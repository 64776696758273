.homepage-section {
    margin-bottom: 40px;
    @include media-query($medium-up) {
        margin-bottom: 90px;
    }
    &.hero {
        min-height: 550px;
        background-color: $hero-sfnt-color;
        align-content: center;
        border-radius: 0 0 90px 90px;
        padding: 10px 10%;
        @include media-query($small-down) {
            text-align: center;
        }
        &.grid {
            margin-left: 0;
        }
        .text-area {
            margin: auto;
            p {
                margin-bottom: 30px;
            }
        }
    }
}


.t-storefront-hero-carousel {
    position: relative;
    .heroCarousel {
        margin-bottom: 30px;
        @include media-query($medium-up) {
            margin-bottom: 90px;
        }
    }
    .heroCarousel-slide {
        background-size: cover;
        height: 150px;
        padding-top: 20px;
        @include media-query($medium-up) {
            height: 600px;
            padding-top: 110px;
        }
        .slide-content {
            max-width: 113px;
            @include media-query($medium-up) {
                max-width: 455px;
                padding-left: 40px;
            }
        }
        .slide-title {
            margin-bottom: 0;
            @include media-query($medium-up) {
                margin-bottom: 25px;
            }
        }
    }
}

// featured categories
.t-storefront-featured-categories {
    h2 {
        margin-bottom: 40px;
    }
    @include media-query($medium-up) {
        margin-bottom: 80px;
    }
    .category-short-desc {
        background: linear-gradient(180deg, #005F9F 0%, #0078C1 100%);
        color: $color-white;
        padding: 20px 22px 15px;
        // border-top: 5px solid $color-light-blue;
    }
    .image-wrapper {
        margin-bottom: 22px;
        @include media-query($medium-up) {
            // min-width: 466px;
            // min-height: 282px;

        }
    }
    .bigger-image-wrapper {
        margin-bottom: 22px;
        @include media-query($medium-down) {
            // height: 282px;
            width: 100%;
        }
        @include media-query($medium-up) {
            // min-width: 466px;
            // min-height: 586px;
        }
    }
    .image-wrapper, .bigger-image-wrapper {
        border-radius: 20px;
        .category-title {
            position: absolute;
            bottom: 35px;
            margin-bottom: 0px;
            margin-left: 35px;
        }
    }
}

// hover and swap image style
.hover-swap-image {
    &:hover {
        .image-hover {
            opacity: 1;
        }
        .hover-underline-animation {
            &:before {
                width: 100%;
            }
        }
    }
    .hover-underline-animation {
        &:before {
            content: "";
            width: 0;
            height: 5px;
            background: $color-primary;
            border-radius: 10px;
            position: absolute;
            left: 0;
            bottom: -10px;
            transition: all 0.3s ease-out;
        }
    }
    .bigger-image-wrapper, .image-wrapper {
        position: relative;
        overflow: hidden;
    }

    .image {
        transition: transform .5s ease;
        &:hover {
            transform: scale(1.1);
        }
    }

}

.hover-underline-animation {
    // display: inline-block;
    position: relative;
}

.t-storefront-about {
    display: flex;
    @include media-query($large-up) {
        margin: 60px 80px;
    }
    .about-card {
        .icon {
            height: 80px;
            width: 120px;
            margin-bottom: 10px;
        }
        padding: 0px 45px;
        div {
            width: 120px;
            margin: auto;
            &.color-one {
                border-bottom: 4px solid $color-primary;
            }
            &.color-two {
                border-bottom: 4px solid $color-secondary;
            }
            &.color-three {
                border-bottom: 4px solid $color-tertiary;
                svg {
                    width: 96px;
                }
            }
        }
        p.h3 {
            margin-top: 36px;
        }
        .content {
            font-weight: 700;
        }
    }
}

.t-storefront-our-story {
    .h2 {
        margin-bottom: 30px;
    }
    .year {
        line-height: 21px;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        .icon {
            height: 20px;
            width: 30px;
        }
    }
    span {
        .icon {
            height: 140px;
            width: 140px;
            margin-bottom: 30px;
        }
    }
    .about-card {
        padding: 0px 60px;
    }
}

.t-storefront-call-to-action {
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 40px;
    .text-wrapper {
        margin-top: 20px;
        max-width: 400px;
    }
    @include media-query($large-up) {
        background-position: top center;
    }
    @include media-query($medium-up) {
        padding: 0px 120px;
        padding-top: 90px;
        .image-wrapper {
            display: flex;
            justify-content: center;
            img {
                width: 588px;
            }
        }
        .text-wrapper {
            margin: auto;
            margin-left: 60px;
        }
    }
}

// Brands
.t-storefront-logo-brands {
    // background-color: $color-gray-10;
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-query($medium-up) {
        margin: 0px 140px;
    }
    .brands {
        // max-width: 1376px;
        // margin-left: auto;
        // margin-right: auto;
        // margin-bottom: 40px;
        .next, .prev {
            svg {
                .not-hover {
                    display: block;
                }
                .hover {
                    display: none;
                }
            }
            &:hover svg {
                .not-hover {
                    display: none;
                }
                .hover {
                    display: block;
                }
            }
        }
    }
    .brand-slider {
        display: flex !important;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }
    .brand-logo {
        width: 100px;
        height: 100px;
        display: inline-block;
        // padding: 10px;
        border-radius: 6px;
        // background-color: $color-white;
        margin-right: 2px;
        margin-bottom: 2px;
        @include media-query($small-up) {
            width: 140px;
            height: 140px;
            padding: 20px;
        }
        @include media-query($medium-up) {
            width: 222px;
            height: auto;
            padding: 25px;
        }
    }
    .container {
        position: relative;
        width: auto;
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 12px;
            margin-top: 10px;
            height: 80%;
            width: 100%;
            opacity: 0;
            transition: .3s ease;
            background-color: $color-white;
            box-shadow: 0px 0px 10px 0px $color-light-gray;
        }
    }
    .container:hover .overlay {
        opacity: 1;
    }
    .container:hover {
        bottom: 3px;
    }
    .text {
        position: absolute;
        top: 55%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 10px;
        font-weight: 800;
    }
    .prev, .next{
        top: 50px;
        @media screen and (min-width: 767px) {
            top: 50%;
        }
    }
    .prev {
        left: -12px;
        @media screen and (min-width: 1552px) {
            left: 50px;
        }
    }
    .next {
        right: -12px;
        @media screen and (min-width: 1552px) {
            right: 50px;
        }
    }

    .slick-dots {
        bottom: -5px;
    }
    .slick-arrow {
        color: $color-white;
    }
    [role="tab"][aria-selected] {
        background-color: $color-copy;
        border-color: $color-copy;
    }
    .slick-dots li button {
        border: 1px solid $color-light-gray;
        background: $color-light-gray;
    }
}

.t-storefront-best-selling {
    background-color: $color-lightest-gray;
    border-radius: 90px;
    padding: 10px 0;

    @include media-query($medium-up) {
        margin: 100px 0px;
        padding: 90px 0;
    }
}
