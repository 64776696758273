/* Wish List Page
=========================================== */
.t-wish-list-search .c-control-group {
	border: $global-border-width solid $color-lightest-gray;
}

.t-wish-list-search__button {
	padding-right: calc(1em - #{$global-border-width});
	padding-left: calc(1em - #{$global-border-width});
}


.t-wish-list__item .c-control-group__button {
	flex: 1 1 50%;
	padding-right: 0;
	padding-left: 0;
}

.wishlist-item-remove {
	margin-top: 10px;
}
