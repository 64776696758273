/* Product Page
=========================================== */
.t-quantify .c-control-group__button,
.t-quantify .c-control-group__field {
	max-width: calc(100% / 4);
	flex-basis: calc(100% / 4);
}

.related-products {
	overflow: hidden;
	.page-heading {
		text-align: left;
		margin-bottom: 43px;
	}
	.swiper {
		overflow: visible;
		.swiper-button-prev {
			top: -76px;
			right: 66px;
			left: auto;
			transform: none;
			margin: 0;
		}
		.swiper-button-next {
			top: -76px;
			right: 0;
			transform: none;
			margin: 0;
		}
	}
}
