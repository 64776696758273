
/* ==========================================================================
COMPONENTS / TYPOGRAPHY
========================================================================== */
.c-heading--subheading {
	font-size: 0.6em;
	font-weight: 400;
}


.c-heading--keyline {
	display: inline-block;
	margin-bottom: 1em;
	position: relative;
}

.c-heading--keyline::after {
	display: block;
	width: 25%;
	height: 2px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0.5em);
	background-color: $color-light-gray;
	content: "";
}



/* Text-level typography
=========================================== */
.c-text-body {
	font-size:  $global-font-size;
}



/* ==========================================================================
ELEMENTS / TYPOGRAPHY
========================================================================== */

/* Headings
=========================================== */

/**
* Force all headings to have the exact same *default* styling. This means we
* are free to use the correct semantic element without having opinionated look-
* and-feel attached to it. Provide all cosmetics for specific visual use cases
* via heading classes (i.e. `.c-heading-*`).
*
* http://csswizardry.com/2016/02/managing-typography-on-large-apps/
*/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size:  $global-font-size;
	font-weight: normal;
}

/* Other text-level elements
=========================================== */

/**
* As above, leave SMALL as a purely semantic choice, and omit any cosmetics.
*/
small {
	font: inherit;
}

/* ==========================================================================
UTILITIES / TYPOGRAPHY
========================================================================== */

/* Font sizing utilities
=========================================== */
.u-font-tiny {
	font-size: 0.625em;
}

.u-font-small {
	font-size: 0.75em;
}

@include media-query($medium-up) {
	.u-font-tiny {
		font-size: 0.75em;
	}

	.u-font-small {
		font-size: 0.832em;
	}
}

/* Font style utilities
* 1. This is a hold-over class for content output by Miva.
=========================================== */
.u-text-light {
	font-weight: 200;
}

.u-text-regular {
	font-weight: 400;
}

.u-text-medium {
	font-weight: 600;
}

.required, /* [1] */
.u-text-bold {
	font-weight: 700;
}

.u-text-boldest {
	font-weight: 900;
}

.u-text-caps {
	font-variant: small-caps;
}

.u-text-italic {
	font-style: italic;
}

.u-text-normal {
	font-style: normal;
}

.u-text-lowercase {
	text-transform: lowercase;
}

.u-text-revert {
	text-transform: none;
}

.u-text-uppercase {
	text-transform: uppercase;
}

.u-text-strike {
	text-decoration: line-through;
}

.u-text-underline {
	text-decoration: underline;
}

a.u-text-underline:hover {
	text-decoration: none;
}

/**
* Text utility to constrain text to a maximum of 75 characters per line,
* regardless of the text's 'font-size'. 30em is roughly equal to 75 characters,
* so we hard-code this value. Please don't change it.
*
* https://jsfiddle.net/5571te7g/
*
*/
.u-text-constrain {
	max-width: 30em;
}
