/* ==========================================================================
EXTENSIONS / FACETS / FACETS
========================================================================== */

.x-facet-set {
    margin-bottom: 38px;

    margin-bottom: 38px;

    .hide-filter,
    .show-less,
    .show-all-filters .show-more {
        display: none;
    }
    .show-all-filters {
        .show-less,
        .c-form-checkbox.hide-filter {
            display: block;
        }
    }

    .c-form-checkbox__caption {
        line-height: 18px;
    }

    .c-form-checkbox__caption {
        line-height: 18px;
    }
}

.x-facet-set__trigger {
    display: none;
}

.x-facet-set__label {
    font-weight: 700;
    margin-bottom: 14px;
}

.x-facet-set__label.x-collapsible-content__toggle {
    padding-bottom: 0.5em;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
}

.x-facet-set__list {
    display: block;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.15s, 0s, 0s;
}

.x-facet-set__list.x-collapsible-content__item[aria-hidden='false'] {
	max-height: 50em;
}

.x-facet-set .c-form-checkbox {
	font-size: 14px;
    margin-bottom: 12px;
}

.applied-filter-tag {
    color: $color-white;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;

    .icon {
        width: 9px;
        height: 9px;
    }

    a {
        color: currentColor;
        display: inline-block;
        padding: 5px 10px;
        background-color: $color-copy;
        border-radius: 6px;
        transition: 0.3s ease;
        margin: 0 15px 15px 0;

        &:hover {
            text-decoration: none;
            background-color: $color-light-gray;
        }
    }
}

.facet-count {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
}

.price-range-wrapper {
    margin-bottom: 15px;
    max-width: 235px;
    .price-range-input {
        display: flex;
        align-items: center;
        strong {
            margin: 0 10px;
            font-weight: 700;
        }
        input, button {
            border-radius: 8px;
            max-width: 58px;
            height: 36px;
        }
        input {
            padding: 0px 7px;
            border: 1px solid $color-copy-secondary;
            text-align: center;
        }
        button {
            padding: 7px 16px;
            margin-left: 13px;
            font-size: 16px;
            line-height: 0px;
        }
    }
}
