/* Category Page
=========================================== */
.t-display-list-filtering {
    flex-wrap: nowrap;
}

.t-display-list-filtering .x-display-list-filtering__per-page,
.t-display-list-filtering .c-form-list__item,
.t-display-list-filtering .c-form-label,
.t-display-list-filtering .c-form-select {
    margin-bottom: 0;
}

.t-display-list-filtering .c-form-label {
    font-size: 0.6875rem;
}

.t-display-list-filtering .c-form-select__dropdown {
    padding: 0;
    font-size: 0.75rem;
    background-color: $color-white;
    border: 0 none;
    color: initial;
}

.t-pagination {
}

.t-pagination.x-pagination--centered {
    width: 13rem;
    justify-content: center;
}

.t-pagination .o-list-inline {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.t-pagination .c-button {
    width: 2em;
    height: 2em;
    padding: 0;
    line-height: 2;
    border-color: $color-light-gray;
}

.t-pagination .c-form-select {
    margin-bottom: 0;
}

.t-pagination .c-form-select__dropdown {
    padding: 0;
    border: 0 none;
}

.x-display-list-filtering__sort-by {
    margin-bottom: $global-spacing-unit;
    .c-form-list__item {
        display: flex;
        align-items: center;
        .c-form-label {
            font-size: 14px;
            font-weight: 700;
            margin-right: 22px;
            @include media-query($large-up) {
                text-align: end;
                width: 100%;
            }
        }
        .c-form-select {
            .c-form-select__dropdown {
                padding: 8px 10px;
                font-size: 14px;
                font-weight: 700;
                color: $color-copy;
                text-transform: capitalize;
                border: 1px solid $color-copy;
                border-radius: 8px;

                @include media-query($large-up) {
                    min-width: 222px;
                }
            }
        }
    }
	.c-form-list__item {
		display: flex;
		align-items: center;
		.c-form-label {
            text-align: end;
			font-size: 14px;
			font-weight: 700;
			margin-right: 22px;

            @include media-query($large-up) {
                width: 100%;
            }
		}
		.c-form-select {
			.c-form-select__dropdown {
				padding: 8px 10px;
				font-size: 14px;
				text-transform: capitalize;
				border: 1px solid $color-copy;


			}
		}
	}
}
