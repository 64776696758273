#js-blog {
    .t-main-content-element {
        min-height: 50vh;
    }
    .skewed-underline {

    }
    a:active,
    a:focus,
    a:hover {
        text-decoration: underline;
    }
    .blog--searchbar {
        input {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        button {
            border: none;
        }
    }
    article {
        hr {
            color: $color-copy-secondary;
            opacity: 0.4;
        }
    }

    #ratings {
        .rateit {
            margin-right: 65%;
            margin-bottom: 10px;
        }
        @include media-query($small-up) {
            text-align: right;
            .rateit {
                margin: 0;
            }
        }
    }
}