
/* ==========================================================================
BASE / RESETS
========================================================================== */

/**
* This contains some additional resets and baselines not facilitated by
* 'normalize.css'. Editing is not recommended.
*/

/**
* Implement a reduced-motion mode if the user has selected this system option.
* Remove _all_ animations and transitions for people that prefer not to see them.
* https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
* https://css-tricks.com/introduction-reduced-motion-media-query/
*/
@media screen and (prefers-reduced-motion: reduce) {
	* {
		animation-play-state: paused !important;
		scroll-behavior: auto !important;
		transition: none !important;
	}
}





/* Vertical Rhythm
=========================================== */

/**
* Apply our base spacing unit as a `margin-bottom` to all block level elements
* so that we get nice and consistent vertical rhythm very cheaply.
* http://csswizardry.com/2012/06/single-direction-margin-declarations/
*/
address,
blockquote,
dl,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
menu,
ol,
p,
pre,
summary,
table,
ul {
	margin-top: 0;
	margin-bottom: $global-spacing-unit;
}

fieldset,
figure {
	margin-right: 0;
	margin-left: 0;
	padding: 0;
}


/**
* We'll also indent list elements by the same amount of spacing. Also, we will
* remove the left padding added by some user-agents.
*/
dd,
ol,
ul {
	margin-left: $global-spacing-unit;
	padding-left: 0;
}

/**
* Remove trailing margins from nested lists.
*/
li > ol,
li > ul {
	margin-bottom: 0;
}





/**
* Pseudo Selection
*/
::-moz-selection {
	background: #eaeaea;
	text-shadow: none;
}

::selection {
	background: #eaeaea;
	text-shadow: none;
}

::selection:window-inactive {
	background: #eaeaea;
}

img::selection {
	background: rgba(0, 0, 0, 0);
}

img::-moz-selection {
	background: rgba(0, 0, 0, 0);
}





/**
* Identify Disabled/Not Allowed Elements
*/
*[disabled] {
	cursor: not-allowed;
}





/**
* Make placeholders have the same positioning as control text in safari.
*/
::placeholder {
	color: $color-copy-secondary;
	line-height: normal;
}





/**
* Breakpoints
* These values will not show up in content, but can be queried by JavaScript
* to know which breakpoint is active.
*/
body::before {
	display: none;
	content: "xsmall";
	visibility: hidden;
}

@media (min-width: 40em) {
	body::before {
		content: "small";
	}
}

@media (min-width: 48em) {
	body::before {
		content: "medium";
	}
}

@media (min-width: 60em) {
	body::before {
		content: "large";
	}
}

@media (min-width: 75em) {
	body::before {
		content: "xlarge";
	}
}

@media (min-width: 90em) {
	body::before {
		content: "wide";
	}
}
