/* ==========================================================================
COMPONENTS / DIVIDER
========================================================================== */

/**
* Divider component to provide more prominent horizontal rules between other
* elements.
*
* The default Divider and it's `--top` modifier are intended for use on
* horizontal rules.
* e.g. `<hr class="c-divider">`
*
* 1. Set a transparent border on the relevant edge to prevent element collapse.
* 2. Reduce the `margin-bottom` by the same width as the divider in order to
*    keep on our baseline grid.
*/
.c-divider {
	position: relative;
	margin-bottom: calc(#{$global-spacing-unit} - #{$global-border-width}); /* 2 */
	border: solid transparent;
	border-width: 0 0 $global-border-width; /* 1 */
}

.c-divider::before,
.c-divider::after {
	display: block;
	position: absolute;
	width: 100%;
	content: "";
}

/**
* Divider gradient border.
*/
.c-divider::before {
	bottom: 0;
	height: $global-border-width;
	background: linear-gradient(to right, rgba(191, 191, 191, 0), rgb(192, 192, 192), rgba(191, 191, 191, 0));
}

/**
* Divider shadow.
*/
.c-divider::after {
	top: 100%;
	height: calc(#{$global-spacing-unit} / 2);
	background: radial-gradient(at 50% 0, rgba(74, 74, 74, .15), transparent 40%);
}

/**
* Top divider
*/

.c-divider--top {
	border-width: $global-border-width 0 0;
}

.c-divider--top::before {
	bottom: 100%;
	background: linear-gradient(to right, rgba(191, 191, 191, 0), rgb(192, 192, 192), rgba(191, 191, 191, 0));
}

.c-divider--top::after {
	top: calc((#{$global-spacing-unit} / 2) + #{$global-border-width} * -1px);
	background: radial-gradient(at 50% 100%, rgba(74, 74, 74, .15), transparent 40%);
}
