/* ==========================================================================
EXTENSIONS / PRODUCT LISTS / PRODUCT LISTS
========================================================================== */

/**
* Product lists are used in many spots throughout the store. They can be found
* on the storefront as featured product, the related products section of a
* product page, as an auto-suggest feature during checkout, or wherever.
*/
.card {
    @apply bg-white rounded-[20px] relative mb-5 mx-auto overflow-hidden md:mb-10;
    // max-width: 270px;
    // min-height: 398px;

    // @include media-query($medium-up) {
    //     min-width: 270px;
    // }
    .x-product-list__image,
    .x-product-list__hover-image {
        transition: 0.5s ease opacity;
    }

    .x-product-list__hover-image {
        opacity: 0;
    }

    .image-wrapper {
        height: 196px;
        display: block;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border: 0;
            max-height: 196px;
            object-fit: contain;
            width: 100%;
        }

        &.has-hover-image:hover {
            .x-product-list__image {
                opacity: 0;
            }
            .x-product-list__hover-image {
                opacity: 1;
            }
        }
    }

    &:hover {
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
    }

    form {
        margin-bottom: 0;
    }
}

.product-list-text {
    padding: 20px;
}

.card-price-section {
    margin-bottom: 14px;
}

.sizes-available {
    margin-bottom: 9px;
}

.product-card-swatches {
    list-style: none;
    display: flex;
    margin: 0;

    li {
        button {
            border: 4px solid $color-white;
            border-radius: 50%;
            height: 28px;
            margin-right: 9px;
            padding: 0;
            width: 28px;

            &.selected {
                &:before {
                    background: none;
                    border: 1px solid $color-copy;
                    content: "";
                    display: block;
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                    border-radius: 50%;
                }
            }
        }
    }
}

.product-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.x-product-list__figure {
    margin: 0;
}

.x-product-list__image,
.x-product-list__name,
.x-product-list__price {
    display: block;
}

.x-product-list__name,
.x-product-list__price {
    font-weight: 700;
    font-size: 16px;
    color: $color-copy;
}

.x-product-list__name {
    margin-bottom: 6px;
}

.x-product-list__price {
    display: inline-block;

    &.base-price {
        color: $color-light-gray;
        font-size: 13px;
    }
}

.x-product-list__action {
    padding-top: $global-spacing-unit;
}

/**
* If you are creating a product carousel, add this class to the element.
*/
.x-product-list--carousel {
}

.x-product-list--carousel .x-product-list__item {
    margin-bottom: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.x-product-list__item {

}

.x-best-selling-card {
    min-height: 398px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    position: relative;
    max-width: 270px;
    @include media-query($small-down) {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .text-wrapper {
        padding-top: 50px;
        p {
            color: $color-white;
        }
    }
    .button-wrapper {
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        min-width: 161px;
    }
    .button {
        padding: 0 39px;
    }
}

.listing-prod-count {
    font-size: 16px;
    font-weight: 700;
}

.relatedProducts {
    .slide {
        @apply h-auto self-stretch flex flex-col;
    }

    .card {
        @apply w-full h-full flex-1 flex flex-col;
    }

    .product-list-text {
        @apply flex-1 flex flex-col;
    }

    .swiper-pagination {
        @include media-query($small-down) {
            bottom: 0;
        }
    }

    &.slick-slider {
        .slick-list {
            @apply -ml-6;
        }

        .slick-track {
            @apply flex flex-nowrap
                    before:hidden before:content-none
                    after:hidden after:content-none;
        }

        .slick-slide {
            @apply pl-6;
        }
    }
}
