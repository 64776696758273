/* ==========================================================================
OBJECTS / LAYOUT
========================================================================== */

/**
* Grid-like layout system.
*
* The Layout object provides us with a column-style layout system. This file
* contains the basic structural elements, but classes should be complemented
* with width Utilities, for example:
*
*	<div class="o-layout">
*		<div class="o-layout__item u-width-6">
*		</div>
*		<div class="o-layout__item u-width-6">
*		</div>
*	</div>
*
* The above will create a two-column structure in which each column will
* fluidly fill half of the width of the parent. We can have more complex
* systems:
*
* 	<div class="o-layout">
* 		<div class="o-layout__item u-width-12 u-width-4--m">
* 		</div>
* 		<div class="o-layout__item u-width-6 u-width-4--m">
* 		</div>
* 		<div class="o-layout__item u-width-6 u-width-4--m">
* 		</div>
* 	</div>
*
* The above will create a system in which the first item will be 100% width
* until we enter our medium breakpoint, when it will become 33.333% width. The
* second and third items will be 50% of their parent, until they also become
* 33.333% width at the medium breakpoint.
*
* We can also manipulate entire layout systems by adding a series of Modifiers
* to the `.o-layout` Block. For example:
*
*	<div class="o-layout  o-layout--reverse">
*
* This will reverse the displayed order of the system so that it runs in the
* opposite order to our source, effectively flipping the system over.
*
*	<div class="o-layout  o-layout--[right|center]">
*
* This will cause the system to fill up from either the centre or the right
* hand side. Default behaviour is to fill up the layout system from the left.
*
* There are plenty more options available to us: explore them below.
*/

/* Default/mandatory classes.
=========================================== */
.o-layout {
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 0 0 calc(-1 *  #{$global-spacing-unit});
	padding: 0;
	list-style: none;
}

.o-layout__item {
	flex: 1 1 auto;
	max-width: 100%;
	padding-left: $global-spacing-unit;
}


.o-layout.o-layout__item {
	margin-left: 0;
}





/* Layout Display Direction.
* The flex-direction property specifies the direction of the flexible items
* inside the flex container. The default value of flex-direction is row
* (left-to-right, top-to-bottom).
=========================================== */
/**
* To reverse the layout direction, use o-layout--row-reverse.
*/
.o-layout--row-reverse {
	flex-direction: row-reverse;
}

/**
* Use o-layout--column to set the flex items vertically.
*/
.o-layout--column {
	align-items: flex-start;
	flex-direction: column;
}

.o-layout--column > .o-layout__item[class*='u-width-12'],
.o-layout--column-reverse > .o-layout__item[class*='u-width-12'] {
	width: 100%;
}

/**
* Same as column, but reversed.
*/
.o-layout--column-reverse {
	align-items: flex-start;
	flex-direction: column-reverse;
}


@include media-query($medium-up) {
	.o-layout--row--m {
		flex-direction: row;
	}

	.o-layout--row-reverse--m {
		flex-direction: row-reverse;
	}

	.o-layout--column--m {
		flex-direction: column;
	}

	.o-layout--column-reverse--m {
		flex-direction: column-reverse;
	}
}


@include media-query($large-up) {
	.o-layout--row--l {
		flex-direction: row;
	}

	.o-layout--row-reverse--l {
		flex-direction: row-reverse;
	}

	.o-layout--column--l {
		flex-direction: column;
	}

	.o-layout--column-reverse--l {
		flex-direction: column-reverse;
	}
}


@include media-query($xlarge-up) {
	.o-layout--row--w {
		flex-direction: row;
	}

	.o-layout--row-reverse--w {
		flex-direction: row-reverse;
	}

	.o-layout--column--w {
		flex-direction: column;
	}

	.o-layout--column-reverse--w {
		flex-direction: column-reverse;
	}
}






/* Gutter size modifiers.
=========================================== */
/**
* Smaller gutters between items.
*/
.o-layout--narrow {
	margin-left: calc(-0.5 *  #{$global-spacing-unit});
}

.o-layout--narrow > .o-layout__item {
	padding-left: calc(0.5 *  #{$global-spacing-unit});
}


/**
* Larger gutters between items.
*/
.o-layout--wide {
	margin-left: calc(-2 *  #{$global-spacing-unit});
}

.o-layout--wide > .o-layout__item {
	padding-left: calc(2 *  #{$global-spacing-unit});
}


/**
* No gutters between items.
*/
.o-layout--flush {
	margin-left: 0;
}

.o-layout--flush > .o-layout__item {
	padding-left: 0;
}


/**
* Make full width of parent.
*/
.o-layout--full {
	margin-right: calc(-1 *  #{$global-spacing-unit});
}





/* Vertical gutter modifiers.
=========================================== */
/**
* Include vertical gutters on layout items.
*/
.o-layout--spaced > .o-layout__item {
	margin-bottom: $global-spacing-unit;
}

/**
* If we've chosen to change the size of the horizontal gutters, let's change
* the vertical gutters accordingly.
*/
.o-layout--spaced.o-layout--narrow > .o-layout__item {
	margin-bottom: calc(0.5 *  #{$global-spacing-unit});
}

.o-layout--spaced.o-layout--wide > .o-layout__item {
	margin-bottom: calc(2 *  #{$global-spacing-unit});
}





/* Align-Items modifiers.
* The align-items property controls how flex items are positioned
* when the items do not use all available space along the container's cross-axis.
=========================================== */
/**
* Items are positioned at the start of the container's cross-axis.
*/
.o-layout--align-top {
	align-items: flex-start;
}

/**
* Items are positioned along the center of the container's cross-axis.
*/
.o-layout--align-center {
	align-items: center;
}

/**
* Items are positioned at the end of the container's cross-axis.
*/
.o-layout--align-bottom {
	align-items: flex-end;
}

/**
* Items are positioned at along the container's cross-axis so that their baselines align.
*/
.o-layout--align-baseline {
	align-items: baseline;
}

/**
* Default value. Items are stretched to fit the container's cross-axis.
*/
.o-layout--align-stretch {
	align-items: stretch;
}





/* Align-Content modifiers.
* The align-content property controls how lines are positioned in multi-line containers.
=========================================== */
/**
* Default value. Lines are packed at the start of the container's cross-axis.
*/
.o-layout--align-content-start {
	align-content: flex-start;
}

/**
* Lines are packed along the center of the container's cross-axis.
*/
.o-layout--align-content-center {
	align-content: center;
}

/**
* Lines are packed at the end of the container's cross-axis.
*/
.o-layout--align-content-end {
	align-content: flex-end;
}

/**
* Lines are distributed along the container's cross-axis with equal spacing around each line.
*/
.o-layout--align-content-around {
	align-content: space-around;
}

/**
* Lines are distributed along the container's cross-axis with equal spacing between each line.
*/
.o-layout--align-content-between {
	align-content: space-between;
}





/* Align-Self modifiers.
* The align-self property controls how individual flex items are positioned along the container's cross-axis.
=========================================== */
/**
* Default value. Items are aligned based on the flex container's `align-items` value.
*/
.o-layout--align-self-auto {
	align-self: auto;
}

/**
* Items are aligned to the start of the flex container's cross-axis, despite the container's `align-items` value.
*/
.o-layout--align-self-start {
	align-self: flex-start;
}

/**
* Items are aligned along the center of the flex container's cross-axis, despite the container's `align-items` value.
*/
.o-layout--align-self-center {
	align-self: center;
}

/**
* Items are aligned to the end of the flex container's cross-axis, despite the container's `align-items` value.
*/
.o-layout--align-self-end {
	align-self: flex-end;
}

/**
* Items are stretched to fill the flex container's cross-axis, despite the container's `align-items` value.
*/
.o-layout--align-self-stretch {
	align-self: stretch;
}





/* Justify-Content modifiers.
* The justify-content property controls how flex items are positioned
* when the items do not use all available space along the container's main-axis.
=========================================== */
/**
* Default value. Items are justified at the start of the container's main-axis.
*/
.o-layout--justify-start {
	justify-content: flex-start;
}

/**
* Items are justified along the center of the container's main-axis.
*/
.o-layout--justify-center {
	justify-content: center;
}

/**
* Items are justified at the end of the container's main-axis.
*/
.o-layout--justify-end {
	justify-content: flex-end;
}

/**
* Items are justified along the container's main-axis with equal spacing around each item.
*/
.o-layout--justify-around {
	justify-content: space-around;
}

/**
* Items are justified along the container's main-axis with equal spacing between each item.
*/
.o-layout--justify-between {
	justify-content: space-between;
}





/* Growth control.
=========================================== */
.o-layout--grow {
	flex-grow: 1;
}

.o-layout--no-grow {
	flex-grow: 0;
}

.o-layout--shrink {
	flex-shrink: 1;
}

.o-layout--no-shrink {
	flex-shrink: 0;
}





/* Wrapping modifiers.
=========================================== */
.o-layout--no-wrap {
	flex-wrap: nowrap;
}

.o-layout--wrap {
	flex-wrap: wrap;
}

.o-layout--wrap-reverse {
	flex-wrap: wrap-reverse;
}





/* Flex ordering modifiers.
* Set an item to be first or last in grid.
=========================================== */
.o-layout__first {
	order: -1;
}

.o-layout__last {
	order: 1;
}


@include media-query($medium-up) {
	.o-layout__first--s {
		order: -1;
	}

	.o-layout__last--s {
		order: 1;
	}
}


@include media-query($medium-up) {
	.o-layout__first--m {
		order: -1;
	}

	.o-layout__last--m {
		order: 1;
	}
}


@include media-query($large-up) {
	.o-layout__first--l {
		order: -1;
	}

	.o-layout__last--l {
		order: 1;
	}
}


@include media-query($xlarge-up) {
	.o-layout__first--w {
		order: -1;
	}

	.o-layout__last--w {
		order: 1;
	}
}
