/* ==========================================================================
   EXTENSIONS / SHOW PASSWORD / SHOW PASSWORD
   ========================================================================== */

/**
 * This extension allows a user to reveal the password they have typed.
 */
.x-toggle-password {
	width: 8ch;
	margin-top: -0.125em;
	padding: calc(0.5em - 1px);
	position: absolute;
	// top: 50%;
	padding-top: 15px;
	right: 2%;
	cursor: pointer;
	color: currentColor;
	font-size: 0.75em;
	font-weight: 400;
	text-decoration: underline;
	border: 0 none;
	border-radius: 0;
}

.x-toggle-password--no-label {
	margin-top: 0;
	padding: 0.5em;
	top: 25%;
}

.x-toggle-password:focus,
.x-toggle-password:hover {
    color: #000;
    text-shadow: 0 0 0 currentColor;
    // outline: 2px solid currentColor;
}
