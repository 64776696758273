/* ==========================================================================
COMPONENTS / TABLES
========================================================================== */

/**
* The simple table offers easy to read data with a horizontal divider between
* rows at the expense of occupying more space.
*/
.c-table-simple {
	border-collapse: collapse;
}

.c-table-simple__row {
	border-bottom: $global-border-width solid $color-light-gray;
}

.c-table-simple__cell {
	padding: calc(#{$global-spacing-unit} / 2);
	text-align: left;
	vertical-align: top;
}

.c-table-simple__cell--standard {
	padding: $global-spacing-unit;
}

.c-table-simple__cell--wide {
	padding: calc(#{$global-spacing-unit} * 2);
}


/**
* The stripped table offers easy to read data with alternating background rows.
*/
.c-table-stripped {
	border-collapse: collapse;
}

.c-table-stripped__row:not(:nth-child(even)) {
	background-color: $color-lightest-gray;
}


/**
* This is a basic, responsive table set-up. It does require using a `data-label`
* attribute if you would like to show the row titles.
*/
.c-table-responsive {
	width: 100%;
	border-collapse: collapse;
}

.c-table-responsive_thead {
	display: none;
}

.c-table-responsive__row {
	// display: block;
	margin-bottom: calc(#{$global-spacing-unit} / 2);
	border-bottom: $global-border-width solid $color-light-gray;
}

.c-table-responsive__cell {
	display: block;
	padding-bottom: calc(#{$global-spacing-unit} / 2);
}

.c-table-responsive__cell::before {
	display: block;
	font-weight: 700;
	text-align: left;
	content: attr(data-label);
	margin-bottom: 5px;
}

.c-table-responsive__cell--flex {
	display: flex;
	justify-content: space-between;
}


@include media-query($medium-up) {
	.c-table-responsive_thead {
		display: table-header-group;
	}

	.c-table-responsive__row {
		display: table-row;
		margin-bottom: 0;
	}

	.c-table-responsive__cell {
		display: table-cell;
		padding: calc(#{$global-spacing-unit} / 2);
		text-align: left;
		vertical-align: top;
	}

	.c-table-responsive__cell--standard {
		padding: $global-spacing-unit;
	}

	.c-table-responsive__cell--wide {
		padding: calc(#{$global-spacing-unit} * 2);
	}

	.c-table-responsive__cell::before {
		display: none;
		content: '';
	}
}



/* ==========================================================================
ELEMENTS / TABLES
========================================================================== */

/**
* Table styles are incredibly un-opinionated. Simply:
*
* 1. Force TABLEs to be full-width by default.
* 2. Ensure their first and last cells in each row carry no indents.
*/

table {
	width: 100%; /* [1] */
}

th:first-child,
td:first-child{
	padding-left: 0; /* [2] */
}

th:last-child,
td:last-child {
	padding-right: 0; /* [2] */
}


/* ==========================================================================
OBJECTS / TABLES
========================================================================== */

/**
* A simple object for manipulating the structure of HTML 'table's.
*/
.o-table {
	width: 100%;
}


/* Equal-width table cells.
========================================================================== */
/**
* 'table-layout: fixed' forces all cells within a table to occupy the same
* width as each other. This also has performance benefits: because the browser
* does not need to (re)calculate cell dimensions based on content it discovers,
* the table can be rendered very quickly. Further reading:
* https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
*/
.o-table--fixed {
	table-layout: fixed;
}





/* Padding variants.
========================================================================== */
.o-table--narrow th,
.o-table--narrow td {
	padding: calc(0.5 *  #{$global-spacing-unit});
}

.o-table--wide th,
.o-table--wide td {
	padding: calc(2 *  #{$global-spacing-unit});
}





/* Borderless table
* Removes default border-bottom on TABLE rows.
=========================================== */
.o-table-borderless tr {
	border: none;
}
