/* Checkout Pages
=========================================== */
// Login or Continue as Guest
.login-or-continue {
	padding-top: 30px;
	.loc-block {
		text-align: center;
		max-width: 450px;
		margin: 0 auto 20px;

		.c-form-list {
			text-align: left;
		}
	}
	.c-form-list__item--full {
		.button {
			margin-bottom: 15px;
		}
	}
}

.t-ocst-account-message {
	display: block;
}

.t-checkout-customer-summary,
.t-checkout-basket-summary {
	padding: 2rem 2rem 0;
	background-color: #f2f2f2;
}

.t-checkout-basket-summary__product-summary tbody .c-table-simple__cell {
	padding-top: 1.5rem;
}

.t-checkout-basket-summary__image {
	margin-right: 16px;
    max-width: 100px;
}

.t-checkout-basket-summary__promo-form,
.t-checkout-basket-summary__promo-form:first-child,
.t-checkout-basket-summary__promo-form:last-child {
	display: block;
	margin: -1px -2rem;
	padding-right: 2rem;
	padding-left: 2rem;
}

@media (max-width: 59.9em) {
	.t-page-ordl .t-site-header__logo,
	.t-page-ocst .t-site-header__logo,
	.t-page-osel .t-site-header__logo,
	.t-page-opay .t-site-header__logo {
		flex-basis: calc(100% / 12 * 12);
		max-width: calc(100% / 12 * 12);
	}
}

@media (min-width: 90em) {
	.t-page-ocst .t-main-content-element,
	.t-page-osel .t-main-content-element,
	.t-page-opay .t-main-content-element {
		max-width: none;
		padding-right: 0;
		padding-left: 0;
	}


	.t-checkout-basket-summary {
		height: 100%;
	}

	.t-checkout-basket-summary__promo-form,
	.t-checkout-basket-summary__promo-form:first-child,
	.t-checkout-basket-summary__promo-form:last-child {
		margin: -1px 0;
	}


	.t-page-ocst .t-site-footer,
	.t-page-osel .t-site-footer,
	.t-page-opay .t-site-footer {
		padding-top: 0;
	}

	.t-page-ocst .t-site-footer__disclaimer,
	.t-page-osel .t-site-footer__disclaimer,
	.t-page-opay .t-site-footer__disclaimer {
		padding-top: 1em;
		padding-bottom: 1em;
		text-align: left;
	}

	.t-page-ocst .t-site-footer__disclaimer .o-layout__item,
	.t-page-osel .t-site-footer__disclaimer .o-layout__item,
	.t-page-opay .t-site-footer__disclaimer .o-layout__item {
		flex: 0;
		white-space: nowrap;
	}
}

@media (min-width: 48em) {
	.t-payment-cta {
		flex-grow: 0;
	}

	.t-payment-form .o-layout__item {
		display: flex;
		max-width: 75%;
		align-items: center;
	}

	.t-payment-form .c-form-label {
		margin-right: 1rem;
	}

	.t-payment-form .c-form-input {
		max-width: 50%;
	}

	.t-payment-form .c-form-input--cvv {
		max-width: none;
	}

	.t-payment-form .t-data-cvv {
		margin-left: 0.5rem;
	}

	.t-payment-form .c-control-group {
		max-width: 50%;
		flex-grow: 1;
	}
}

.t-payment-form .c-form-label {
    width: 100%;
}


.t-checkout-customer-summary {
	margin-bottom: 1rem;
	padding: 1rem 2rem 0;
	line-height: 1.5;

	a {
		margin-top: 1rem;
		display: inline-block;
	}
}

.t-checkout-customer-summary .c-keyline {
	border-color: $color-light-gray;
}

.t-shipping-method .c-form-checkbox__caption::before {
	// position: absolute;
}

.t-shipping-method-name {
	max-width: 45ch;
}

.t-shipping-method .c-keyline {
	margin-bottom: 0;
}


.t-order-complete-heading {
	padding: 2em 2em 1em 1em;
}

.t-order-complete-heading form {
	padding-top: 0.5em;
}

.t-page-invc .x-toggle-password {
	margin-top: 0;
	padding: 0.5em;
	top: 25%;
}


// OSEL
.shipping-option {
	width: 100%;

	@include media-query($medium-up) {
		min-width: 600px;
	}

	.c-keyline {
		margin-right: 10px;
		margin-left: 10px;
		width: -webkit-fill-available;
	}
}


// INVC
.order-confirmation {
	margin-bottom: 15px;

	@include media-query($medium-up) {
		margin-bottom: 30px;
	}
}

// OCST
#js-OCST {
	[data-validate-address] {
		.button.button--primary {
			margin-top: 15px;
		}
	}
}
