/* Site Footer
=========================================== */
.footer-top {
    background-color: $color-copy;
    border-radius: 70px 70px 0 0;
    padding: 20px;

    @include media-query($medium-up) {
        padding: 25px 75px;
    }

    @include media-query($xlarge-up) {
        padding: 50px 120px;
    }

    .c-menu__list {
        h5,
        li {
            margin-bottom: 10px;
            line-height: 18px;
        }
        address,
        p,
        li {
            color: $color-light-gray;
            font-size: 13px;
            font-weight: 600;
        }
        address {
            font-style: normal;
            line-height: 17px;
        }
        a {
            &:hover {
                text-decoration: underline;
                color: $color-white;
            }
        }
    }
    .c-menu__title {
        color: $color-white;
        font-size: 15px;
        font-weight: 700;
    }
}

.t-site-footer__content .c-menu__link:hover {
    text-decoration: none;
    opacity: 0.75;
}

.t-global-footer-social-links {
    max-width: 183px;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-menu__link {
        border: 1px solid rgba($color-primary, 0.3);
        border-radius: 50px;
        height: 36px;
        line-height: 33px;
        text-align: center;
        width: 36px;
        transition: 0.3s ease;

        &:hover {
            text-decoration: none;
            background-color: $color-secondary;
            border-color: $color-secondary;

            .icon {
                color: $color-copy;
            }
        }
    }

    .icon {
        color: $color-white;
        height: 18px;
    }

    .icon--instagram {
        width: 18px;
    }

    .icon--facebook {
        width: 10px;
    }

    .icon--twitter {
        height: 14px;
        width: 18px;
    }
}

.newsletter-form {
    p {
        color: $color-light-gray;
    }

    .button {
        @apply px-4 leading-none;

        .icon {
            height: 12px;
            width: 21px;
        }

        &,
        &:hover {
            background-color: $color-white;
        }
    }

    .form {
        margin-bottom: 18px;
        max-width: 311px;

        @include media-query($large-up) {
            margin-left: auto;
        }
    }

    .c-control-group__button,
    .t-newsletter-form__input {
        height: 42px;
    }

    .c-form-input,
    .button {
        border: none;
    }

    .c-form-input:first-child,
    .c-control-group__button:first-child {
        @apply rounded-l-full;
    }

    .c-form-input:last-child,
    .c-control-group__button:last-child {
        @apply rounded-r-full;
    }

    .c-form-input {
        @apply rounded-l-full;
    }

    .c-control-group__button {
        @apply rounded-r-full;
    }

}

.footer-bottom {
    background-color: $color-primary;
    height: 15px;
}

.footer-logo {
    height: 68px;
    margin-bottom: 20px;
    width: 184px;

    @include media-query($medium-up) {
        margin-bottom: 32px;
    }
}
