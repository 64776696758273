/* ==========================================================================
EXTENSIONS / BREADCRUMBS / COLLAPSING / COLLAPSING BREADCRUMBS
========================================================================== */

.x-collapsing-breadcrumbs {
	@apply uppercase;
}

.x-collapsing-breadcrumbs__group,
.x-collapsing-breadcrumbs__list {
	@apply mb-0 text-sm border-b-0;
	font-weight: 600;
}

.x-collapsing-breadcrumbs__group {
	@apply mb-0 border-b-0;
}

.x-collapsing-breadcrumbs__list {
	@apply pt-8 mb-8;

	// border-bottom: $global-border-width solid $color-light-gray;
	// .o-lis
	.o-list-inline__item {
		a {
			font-size: 13px;
			color: $color-dark-gray;
			text-transform: capitalize;
			span {
				text-decoration: underline;
			}
		}
		&.u-text-bold {
			a {
				color: $color-primary;
				span {
					text-decoration: none;
				}
			}
		}
	}
}

.x-collapsing-breadcrumbs__button {
	padding-top: 0;
	padding-bottom: 0;
	font-size:  $global-font-size;
}

.x-collapsing-breadcrumbs__list .o-list-inline__item:not(:last-child) {
	margin-right: 10px;
	a {
		color: $color-dark-gray;
	}
}

.x-collapsing-breadcrumbs .o-list-inline__item:not(:last-of-type)::after {
	display: inline-block;
	height: 18px;
	font-size: 13px;
	line-height: 18px;
	position: relative;
	top: 0;
	right: -5px;
	color: $color-dark-gray;
	content: '|';
}
