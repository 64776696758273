/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
@use "sass:math";

$grid-small: $grid_small;
$grid-medium: $grid_medium;
$grid-large: $grid_large;
$grid-xlarge: $grid_xlarge;
$grid-widescreen: $grid_widescreen;
$grid-gutter: $grid_gutter;

$xsmall: 'xsmall';
$small: 'small';
$small-down: 'small-down';
$small-up: 'small-up';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$xlarge: 'xlarge';
$xlarge-down: 'xlarge-down';
$xlarge-up: 'xlarge-up';
$widescreen: 'widescreen';

// The `$breakpoints` list is used to build our media queries.
// You can use these in the media-query mixin.
$breakpoints: (
    $xsmall '(max-width: #{$grid-small - 1})',
    $small '(min-width: #{$grid-small}) and (max-width: #{$grid-medium - 1})',
    $small-down '(max-width: #{$grid-medium - 1})',
    $small-up '(min-width: #{$grid-small})',
    $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
    $medium-down '(max-width: #{$grid-large - 1})',
    $medium-up '(min-width: #{$grid-medium})',
    $large '(min-width: #{$grid-large}) and (max-width: #{$grid-xlarge - 1})',
    $large-down '(max-width: #{$grid-xlarge - 1})',
    $large-up '(min-width: #{$grid-large})',
    $xlarge '(min-width: #{$grid-xlarge}) and (max-width: #{$grid-widescreen - 1})',
    $xlarge-down '(max-width: #{$grid-widescreen - 1})',
    $xlarge-up '(min-width: #{$grid-xlarge})',
    $widescreen '(min-width: #{$grid-widescreen})'
);

/*============================================================================
  Generate breakpoint-specific column widths and push classes
    - Default column widths: $breakpoint-has-widths: ($small, $medium-up);
    - Default is no push classes
    - Will not work if `styles/global/grid.scss` is removed
==============================================================================*/
$breakpoint-has-widths: ($xsmall, $small, $small-down, $small-up, $medium, $medium-up, $large-up, $large, $xlarge-up, $xlarge-down, $xlarge, $medium-down, $widescreen);
$breakpoint-has-push: ();

/*================ Sizing Variables ================*/
$gutter: $grid-gutter;
$width-site: $wrapper_width_default;
$column-width: math.div($width-site - ($grid-gutter * 11), 12);


/*============================================================================
  Grid
    - Based on CSS Wizardry grid
==============================================================================*/
.grid {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -$grid-gutter;
    display: flex !important;
    flex-wrap: wrap;
}

.grid__item {
    flex: 1 1 100%;
    max-width: 100%;
    width: auto;
    padding-left: $grid-gutter;
}

/*============================================================================
  Grid Columns
    - Create width classes, prepended by the breakpoint name.
==============================================================================*/
// sass-lint:disable brace-style empty-line-between-blocks
@mixin grid-column-generator($breakpoint: '') {
    // Auto (Flex Only)
    .#{$breakpoint}auto { flex: 0 0 auto; width: auto; max-width: none }

    // Expand (Flex Only)
    .#{$breakpoint}expand { flex: 1 1 0; max-width: 100%; width: auto; }

    // Whole
    .#{$breakpoint}one-whole { flex: 0 0 100%; max-width: 100%; }

    // Halves
    .#{$breakpoint}one-half { flex: 0 0 percentage(1 * 0.5); max-width: percentage(1 * 0.5); }

    // Thirds
    .#{$breakpoint}one-third { flex: 0 0 percentage(math.div(1, 3)); max-width: percentage(math.div(1, 3)); }
    .#{$breakpoint}two-thirds { flex: 0 0 percentage(math.div(2, 3)); max-width: percentage(math.div(2, 3)); }

    // Quarters
    .#{$breakpoint}one-quarter { flex: 0 0 percentage(1 * 0.25); max-width: percentage(1 * 0.25); }
    .#{$breakpoint}two-quarters { flex: 0 0 percentage(2 * 0.25); max-width: percentage(2 * 0.25); }
    .#{$breakpoint}three-quarters { flex: 0 0 percentage(3 * 0.25); max-width: percentage(3 * 0.25); }

    // Fifths
    .#{$breakpoint}one-fifth { flex: 0 0 percentage(1 * 0.2); max-width: percentage(1 * 0.2); }
    .#{$breakpoint}two-fifths { flex: 0 0 percentage(2 * 0.2); max-width: percentage(2 * 0.2); }
    .#{$breakpoint}three-fifths { flex: 0 0 percentage(3 * 0.2); max-width: percentage(3 * 0.2); }
    .#{$breakpoint}four-fifths { flex: 0 0 percentage(4 * 0.2); max-width: percentage(4 * 0.2); }

    // Sixths
    .#{$breakpoint}one-sixth { flex: 0 0 percentage(math.div(1, 6)); max-width: percentage(math.div(1, 6)); }
    .#{$breakpoint}two-sixths { flex: 0 0 percentage(math.div(2, 6)); max-width: percentage(math.div(2, 6)); }
    .#{$breakpoint}three-sixths { flex: 0 0 percentage(math.div(3, 6)); max-width: percentage(math.div(3, 6)); }
    .#{$breakpoint}four-sixths { flex: 0 0 percentage(math.div(4, 6)); max-width: percentage(math.div(4, 6)); }
    .#{$breakpoint}five-sixths { flex: 0 0 percentage(math.div(5, 6)); max-width: percentage(math.div(5, 6)); }

    // Eighths
    .#{$breakpoint}one-eighth { flex: 0 0 percentage(1 * 0.125); max-width: percentage(1 * 0.125); }
    .#{$breakpoint}two-eighths { flex: 0 0 percentage(2 * 0.125); max-width: percentage(2 * 0.125); }
    .#{$breakpoint}three-eighths { flex: 0 0 percentage(3 * 0.125); max-width: percentage(3 * 0.125); }
    .#{$breakpoint}four-eighths { flex: 0 0 percentage(4 * 0.125); max-width: percentage(4 * 0.125); }
    .#{$breakpoint}five-eighths { flex: 0 0 percentage(5 * 0.125); max-width: percentage(5 * 0.125); }
    .#{$breakpoint}six-eighths { flex: 0 0 percentage(6 * 0.125); max-width: percentage(6 * 0.125); }
    .#{$breakpoint}seven-eighths { flex: 0 0 percentage(7 * 0.125); max-width: percentage(7 * 0.125); }

    // Tenths
    .#{$breakpoint}one-tenth { flex: 0 0 percentage(1 * 0.1); max-width: percentage(1 * 0.1); }
    .#{$breakpoint}two-tenths { flex: 0 0 percentage(2 * 0.1); max-width: percentage(2 * 0.1); }
    .#{$breakpoint}three-tenths { flex: 0 0 percentage(3 * 0.1); max-width: percentage(3 * 0.1); }
    .#{$breakpoint}four-tenths { flex: 0 0 percentage(4 * 0.1); max-width: percentage(4 * 0.1); }
    .#{$breakpoint}five-tenths { flex: 0 0 percentage(5 * 0.1); max-width: percentage(5 * 0.1); }
    .#{$breakpoint}six-tenths { flex: 0 0 percentage(6 * 0.1); max-width: percentage(6 * 0.1); }
    .#{$breakpoint}seven-tenths { flex: 0 0 percentage(7 * 0.1); max-width: percentage(7 * 0.1); }
    .#{$breakpoint}eight-tenths { flex: 0 0 percentage(8 * 0.1); max-width: percentage(8 * 0.1); }
    .#{$breakpoint}nine-tenths { flex: 0 0 percentage(9 * 0.1); max-width: percentage(9 * 0.1); }

    // Twelfths
    .#{$breakpoint}one-twelfth { flex: 0 0 percentage(math.div(1, 12)); max-width: percentage(math.div(1, 12)); }
    .#{$breakpoint}two-twelfths { flex: 0 0 percentage(math.div(2, 12)); max-width: percentage(math.div(2, 12)); }
    .#{$breakpoint}three-twelfths { flex: 0 0 percentage(math.div(3, 12)); max-width: percentage(math.div(3, 12)); }
    .#{$breakpoint}four-twelfths { flex: 0 0 percentage(math.div(4, 12)); max-width: percentage(math.div(4, 12)); }
    .#{$breakpoint}five-twelfths { flex: 0 0 percentage(math.div(5, 12)); max-width: percentage(math.div(5, 12)); }
    .#{$breakpoint}six-twelfths { flex: 0 0 percentage(math.div(6, 12)); max-width: percentage(math.div(6, 12)); }
    .#{$breakpoint}seven-twelfths { flex: 0 0 percentage(math.div(7, 12)); max-width: percentage(math.div(7, 12)); }
    .#{$breakpoint}eight-twelfths { flex: 0 0 percentage(math.div(8, 12)); max-width: percentage(math.div(8, 12)); }
    .#{$breakpoint}nine-twelfths { flex: 0 0 percentage(math.div(9, 12)); max-width: percentage(math.div(9, 12)); }
    .#{$breakpoint}ten-twelfths { flex: 0 0 percentage(math.div(10, 12)); max-width: percentage(math.div(10, 12)); }
    .#{$breakpoint}eleven-twelfths { flex: 0 0 percentage(math.div(11, 12)); max-width: percentage(math.div(11, 12)); }

    // Utilities
    .#{$breakpoint}flex-row { flex-direction: row !important }
    .#{$breakpoint}flex-column { flex-direction: column !important }
    .#{$breakpoint}flex-row-reverse { flex-direction: row-reverse !important }
    .#{$breakpoint}flex-column-reverse { flex-direction: column-reverse !important }
    .#{$breakpoint}flex-wrap { flex-wrap: wrap !important }
    .#{$breakpoint}flex-nowrap { flex-wrap: nowrap !important }
    .#{$breakpoint}flex-wrap-reverse { flex-wrap: wrap-reverse !important }
    .#{$breakpoint}flex-fill { flex: 1 1 auto !important }
    .#{$breakpoint}flex-grow-0 { flex-grow: 0 !important }
    .#{$breakpoint}flex-grow-1 { flex-grow: 1 !important }
    .#{$breakpoint}flex-shrink-0 { flex-shrink: 0 !important }
    .#{$breakpoint}flex-shrink-1 { flex-shrink: 1 !important }
    .#{$breakpoint}justify-content-start { justify-content: flex-start !important }
    .#{$breakpoint}justify-content-end { justify-content: flex-end !important }
    .#{$breakpoint}justify-content-center { justify-content: center !important }
    .#{$breakpoint}justify-content-between { justify-content: space-between !important }
    .#{$breakpoint}justify-content-around { justify-content: space-around !important }
    .#{$breakpoint}align-items-start { align-items: flex-start !important }
    .#{$breakpoint}align-items-end { align-items: flex-end !important }
    .#{$breakpoint}align-items-center { align-items: center !important }
    .#{$breakpoint}align-items-baseline { align-items: baseline !important }
    .#{$breakpoint}align-items-stretch { align-items: stretch !important }
    .#{$breakpoint}align-content-start { align-content: flex-start !important }
    .#{$breakpoint}align-content-end { align-content: flex-end !important }
    .#{$breakpoint}align-content-center { align-content: center !important }
    .#{$breakpoint}align-content-between { align-content: space-between !important }
    .#{$breakpoint}align-content-around { align-content: space-around !important }
    .#{$breakpoint}align-content-stretch { align-content: stretch !important }
    .#{$breakpoint}align-self-auto { align-self: auto !important }
    .#{$breakpoint}align-self-start { align-self: flex-start !important }
    .#{$breakpoint}align-self-end { align-self: flex-end !important }
    .#{$breakpoint}align-self-center { align-self: center !important }
    .#{$breakpoint}align-self-baseline { align-self: baseline !important }
    .#{$breakpoint}align-self-stretch { align-self: stretch !important }

    .#{$breakpoint}order-first { order: -1 }
    .#{$breakpoint}order-last { order: 13 }
    .#{$breakpoint}order-0 { order: 0 }
    .#{$breakpoint}order-1 { order: 1 }
    .#{$breakpoint}order-2 { order: 2 }
    .#{$breakpoint}order-3 { order: 3 }
    .#{$breakpoint}order-4 { order: 4 }
    .#{$breakpoint}order-5 { order: 5 }
    .#{$breakpoint}order-6 { order: 6 }
    .#{$breakpoint}order-7 { order: 7 }
    .#{$breakpoint}order-8 { order: 8 }
    .#{$breakpoint}order-9 { order: 9 }
    .#{$breakpoint}order-10 { order: 10 }
    .#{$breakpoint}order-11 { order: 11 }
    .#{$breakpoint}order-12 { order: 12 }
}

/*================ Grid push classes ================*/
@mixin grid-push-generator($breakpoint: '') {
    // Halves
    .#{$breakpoint}push-one-half { margin-left: percentage(1 * 0.5); }

    // Thirds
    .#{$breakpoint}push-one-third { margin-left: percentage(math.div(1, 3)); }
    .#{$breakpoint}push-two-thirds { margin-left: percentage(math.div(2, 3)); }

    // Quarters
    .#{$breakpoint}push-one-quarter { margin-left: percentage(1 * 0.25); }
    .#{$breakpoint}push-two-quarters { margin-left: percentage(2 * 0.25); }
    .#{$breakpoint}push-three-quarters { margin-left: percentage(3 * 0.25); }

    // Fifths
    .#{$breakpoint}push-one-fifth { margin-left: percentage(1 * 0.2); }
    .#{$breakpoint}push-two-fifths { margin-left: percentage(2 * 0.2); }
    .#{$breakpoint}push-three-fifths { margin-left: percentage(3 * 0.2); }
    .#{$breakpoint}push-four-fifths { margin-left: percentage(4 * 0.2); }

    // Sixths
    .#{$breakpoint}push-one-sixth { margin-left: percentage(math.div(1, 6)); }
    .#{$breakpoint}push-two-sixths { margin-left: percentage(math.div(2, 6)); }
    .#{$breakpoint}push-three-sixths { margin-left: percentage(math.div(3, 6)); }
    .#{$breakpoint}push-four-sixths { margin-left: percentage(math.div(4, 6)); }
    .#{$breakpoint}push-five-sixths { margin-left: percentage(math.div(5, 6)); }

    // Eighths
    .#{$breakpoint}push-one-eighth { margin-left: percentage(1 * 0.125); }
    .#{$breakpoint}push-two-eighths { margin-left: percentage(2 * 0.125); }
    .#{$breakpoint}push-three-eighths { margin-left: percentage(3 * 0.125); }
    .#{$breakpoint}push-four-eighths { margin-left: percentage(4 * 0.125); }
    .#{$breakpoint}push-five-eighths { margin-left: percentage(5 * 0.125); }
    .#{$breakpoint}push-six-eighths { margin-left: percentage(6 * 0.125); }
    .#{$breakpoint}push-seven-eighths { margin-left: percentage(7 * 0.125); }

    // Tenths
    .#{$breakpoint}push-one-tenth { margin-left: percentage(1 * 0.1); }
    .#{$breakpoint}push-two-tenths { margin-left: percentage(2 * 0.1); }
    .#{$breakpoint}push-three-tenths { margin-left: percentage(3 * 0.1); }
    .#{$breakpoint}push-four-tenths { margin-left: percentage(4 * 0.1); }
    .#{$breakpoint}push-five-tenths { margin-left: percentage(5 * 0.1); }
    .#{$breakpoint}push-six-tenths { margin-left: percentage(6 * 0.1); }
    .#{$breakpoint}push-seven-tenths { margin-left: percentage(7 * 0.1); }
    .#{$breakpoint}push-eight-tenths { margin-left: percentage(8 * 0.1); }
    .#{$breakpoint}push-nine-tenths { margin-left: percentage(9 * 0.1); }

    // Twelfths
    .#{$breakpoint}push-one-twelfth { margin-left: percentage(math.div(1, 12)); }
    .#{$breakpoint}push-two-twelfths { margin-left: percentage(math.div(2, 12)); }
    .#{$breakpoint}push-three-twelfths { margin-left: percentage(math.div(3, 12)); }
    .#{$breakpoint}push-four-twelfths { margin-left: percentage(math.div(4, 12)); }
    .#{$breakpoint}push-five-twelfths { margin-left: percentage(math.div(5, 12)); }
    .#{$breakpoint}push-six-twelfths { margin-left: percentage(math.div(6, 12)); }
    .#{$breakpoint}push-seven-twelfths { margin-left: percentage(math.div(7, 12)); }
    .#{$breakpoint}push-eight-twelfths { margin-left: percentage(math.div(8, 12)); }
    .#{$breakpoint}push-nine-twelfths { margin-left: percentage(math.div(9, 12)); }
    .#{$breakpoint}push-ten-twelfths { margin-left: percentage(math.div(10, 12)); }
    .#{$breakpoint}push-eleven-twelfths { margin-left: percentage(math.div(11, 12)); }
}
// sass-lint:enable brace-style empty-line-between-blocks

/*================ Build Base Grid Classes ================*/
@include grid-column-generator();
@include responsive-display-helper();
@include responsive-text-align-helper();

/*================ Build Responsive Grid Classes ================*/
@each $breakpoint in $breakpoint-has-widths {
    @include media-query($breakpoint) {
        @include grid-column-generator('#{$breakpoint}--');
        @include responsive-display-helper('#{$breakpoint}--');
        @include responsive-text-align-helper('#{$breakpoint}--');
    }
}

/*================ Build Grid Push Classes ================*/
@each $breakpoint in $breakpoint-has-push {
    @include media-query($breakpoint) {
        @include grid-push-generator('#{$breakpoint}--');
    }
}

/*================ Special Grid Classes (only add to "grid" element) ================*/
/* Gutterless grids have all the properties of regular grids, minus any spacing. */
.grid--full {
    margin-left: 0;
    width: 100% !important;

    > .grid__item {
        padding-left: 0;
    }
}

/* Extra large gutters. */
.grid--double-gutter {
    margin-left: -$grid-gutter * 2;
    > .grid__item {
        padding-left: $grid-gutter * 2;
    }
}
