// http://sassmeister.com/gist/1b4f2da5527830088e4d
@use "sass:math";

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// https://stackoverflow.com/questions/32376461/how-to-split-a-string-into-two-lists-of-numbers-in-sass
///
/// Split `$string` into a list of two strings at the first occurrence of `$separator`.
///
/// @param {String} $string - The string to be split
/// @param {String} $separator - Character indicating where the split should occur
///
/// @return {List} - A list of the two created strings. The second string will be null if `$separator` is not found.
///
@function str-split($string, $separator) {

    $index : str-index($string,  $separator);

    @if not $index {
        @return $string "";
    }

    $str-1 : str-slice($string, 1, $index - 1);
    $str-2 : str-slice($string, $index + 1);

    @return $str-1 $str-2;
}

// https://www.sassmeister.com/gist/9fa19d254864f33d4a80
///
/// Converts a String representation of a number into a Number
///
/// @param {String} $value - The String to be converted
///
/// @return {Number} - The converted Number
///
@function to-number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    } @else if type-of($value) != 'string' {
        $_: log('Value for `to-number` should be a number or a string.');
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not (index(map-keys($numbers), $character) or $character == '.') {
            @return to-length(if($minus, -$result, $result), str-slice($value, $i))
        }

        @if $character == '.' {
            $digits: 1;
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: $digits * 10;
            $result: $result + math.div(map-get($numbers, $character), $digits);
        }
    }

    @return if($minus, -$result, $result);
}


///
/// Add `$unit` to `$value`
///
/// @param {Number} $value - Value to add unit to
/// @param {String} $unit - String representation of the unit
///
/// @return {Number} - `$value` expressed in `$unit`
///
@function to-length($value, $unit) {
    $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

    @if not index(map-keys($units), $unit) {
        $_: log('Invalid unit `#{$unit}`.');
    }

    @return $value * map-get($units, $unit);
}


///
/// Calculate the required `padding-bottom` value to reserve page space for an image of a given size, represented by `$size`.
///
/// @param {String} $size - A string of the form 'XxY', where 'X' and 'Y' are integers.
///
/// @return {Number} - A percentage indicating the appropriate value of `padding-bottom`.
///
@function get-padding($size) {
    $list: str-split($size, 'x');

    $width: to-number(nth($list, 1));
    $height: to-number(nth($list, 2));

    @return percentage(math.div($height, $width));
}

///
/// Returns a string representing the width (in pixels) of an image with size `$size`.
///
/// @param {String} $size - A string of the form 'XxY', where 'X' and 'Y' are integers.
///
/// @return {String} - A string of the form 'Xpx'
///
@function get-width($size) {
    $list: str-split($size, 'x');

    @return nth($list, 1) + 'px';
}

///
/// Returns a string representing the width (in pixels) of an image with size `$size`.
///
/// @param {String} $size - A string of the form 'XxY', where 'X' and 'Y' are integers.
///
/// @return {String} - A string of the form 'Ypx'
///
@function get-height($size) {
    $list: str-split($size, 'x');

    @return nth($list, 2) + 'px';
}


/*================ Mixins ================*/
@mixin clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }

    // sass-lint:disable
    *zoom: 1;
}

/*============================================================================
Prefix mixin for generating vendor prefixes.
Based on https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/addons/_prefixer.scss

Usage:
// Input:
.element {
    @include prefix(transform, scale(1), ms webkit spec);
}

// Output:
.element {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}
==============================================================================*/
@mixin prefix($property, $value, $prefixes) {
    @each $prefix in $prefixes {
        @if $prefix == webkit {
            -webkit-#{$property}: $value;
        } @else if $prefix == moz {
            -moz-#{$property}: $value;
        } @else if $prefix == ms {
            -ms-#{$property}: $value;
        } @else if $prefix == o {
            -o-#{$property}: $value;
        } @else if $prefix == spec {
            #{$property}: $value;
        } @else  {
            @warn 'Unrecognized prefix: #{$prefix}';
        }
    }
}

/*================ Media Query Mixin ================*/
@mixin media-query($media-query) {
    $breakpoint-found: false;

    @each $breakpoint in $breakpoints {
        $name: nth($breakpoint, 1);
        $declaration: nth($breakpoint, 2);

        @if $media-query == $name and $declaration {
            $breakpoint-found: true;

            @media only screen and #{$declaration} {
                @content;
            }
        }
    }

    @if $breakpoint-found == false {
        @warn 'Breakpoint "#{$media-query}" does not exist';
    }
}

/*================ Responsive Show/Hide Helper ================*/
@mixin responsive-display-helper($breakpoint: '') {
    // sass-lint:disable no-important
    .#{$breakpoint}show {
        display: block !important;
    }

    .#{$breakpoint}hide {
        display: none !important;
    }
}


/*================ Responsive Text Alignment Helper ================*/
@mixin responsive-text-align-helper($breakpoint: '') {
    // sass-lint:disable no-important
    .#{$breakpoint}text-left {
        text-align: left !important;
    }

    .#{$breakpoint}text-right {
        text-align: right !important;
    }

    .#{$breakpoint}text-center {
        text-align: center !important;
    }
}

@mixin visually-hidden() {
    // sass-lint:disable no-important
    position: absolute !important;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}


/**
* Reverse the properties applied by @mixin visually-hidden
*
* Accepts:
* - $position: {String} Positioning method for element
*
* Usage:
* .selector {
*   @include visually-shown(relative);
* }
*/
@mixin visually-shown($position: inherit) {
    // stylelint-disable-next-line
    position: $position !important;
    overflow: auto;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
}

// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They have been removed in v4.

// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select

// Animations
@mixin animation($animation) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}
@mixin animation-name($name) {
    -webkit-animation-name: $name;
    animation-name: $name;
}
@mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function: $timing-function;
    animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count: $iteration-count;
    animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
    -webkit-animation-fill-mode: $fill-mode;
    animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
    -webkit-column-count: $column-count;
    -moz-column-count: $column-count;
    column-count: $column-count;
    -webkit-column-gap: $column-gap;
    -moz-column-gap: $column-gap;
    column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: $mode;
    -moz-hyphens: $mode;
    -ms-hyphens: $mode; // IE10+
    -o-hyphens: $mode;
    hyphens: $mode;
}

// Placeholder text
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// Transformations
@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9 only
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}
@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}
@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}
@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
    -moz-perspective: $perspective;
    perspective: $perspective;
}
@mixin perspective-origin($perspective) {
    -webkit-perspective-origin: $perspective;
    -moz-perspective-origin: $perspective;
    perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}


// Transitions

@mixin bsTransition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}


// User select
// For selecting text on the page

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}


// Fallback Mixin
@mixin vendor-prefix($property, $value...) {
    -webkit-#{$property}: #{$value};
    -khtml-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}

@mixin lazy-loaded-img() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@mixin lazy-loaded-padding($size-param) {
    &:after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: get-padding(stencilString($size-param));
    }
}

@mixin cart-item-lazy-load-img-placeholder() {
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
