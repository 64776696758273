/* ==========================================================================
EXTENSIONS / SEARCH-PREVIEW / SEARCH-PREVIEW
========================================================================== */

.x-search-preview {
	display: none;
	width: 100%;
	margin: 0;
	padding: 0.75rem 0.5rem;
	position: absolute;
	right: 0;
	list-style: none;
	background-color: $color-white;
    border: 1px solid $color-copy;
    border-radius: 20px;
	opacity: 0;
	transition: opacity ease-in-out 0.5s;
    top: 45px;
	z-index: 500;
}

.x-search-preview--open {
	opacity: 1;
}

.x-search-preview__entry {
	display: block;
}

.x-search-preview__list {
	padding: 0 0.5rem;
}

.x-search-preview__line {
	display: flex;
	padding: 0.5rem 0;
	position: relative;
	align-items: center;
	border-bottom: $global-border-width solid $color-lightest-gray;
	cursor: pointer;
}

.x-search-preview__image {
	width: 4rem;
	height: 4rem;
	margin: 0 1em 0 0;
	overflow: hidden;
	text-align: center;
}

.x-search-preview__item {
	display: flex;
	flex: 1;
	justify-content: space-between;
	text-decoration: none;
}

.x-search-preview__item--selected {
	background-color: $color-lightest-gray;
}

.x-search-preview__search-all {
	padding: 0.5rem;
	font-style: italic;
	cursor: pointer;
}

.x-search-preview__item-name {
    font-weight: 600;
}

/**
* This section contains the necessary styles when using the built-in search
* preview ability of Miva. Since the functional code is generated at run-time,
* these styles do not conform to the tenants used in the rest of the framework.
*/

.mm_searchfield_menuitem_selected {
	background-color: $color-lightest-gray;
}
