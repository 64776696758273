
/* Basket Page
=========================================== */
.t-basket {
    padding-top: 30px
}

.t-basket {
    .large-up--two-thirds {
        padding-right: 20px;
    }
}

.t-basket__product-summary .c-table-responsive__row,
.t-basket__order-summary .c-table-simple__row {
    border-color: $color-lightest-gray;
}

.t-basket__product-summary thead {
    border-top: $global-border-width solid $color-lightest-gray;
}

.t-basket__product-summary tbody .c-table-responsive__cell {
    padding-top: 1.5rem;
    @include media-query($small-down) {
        padding-top: 0px;
    }
}

.t-basket {
    .c-table-responsive__cell {
        @include media-query($small-up) {
            // text-align: right !important;
        }
    }
}

.save-cart {
	padding-top: 10px;
}

.t-basket__product-details {
    font-size: 0.875rem;
    line-height: 21px;
}


.t-basket-update .x-quantify {
    width: 10em;
    font-size: 0.875rem;
    .c-control-group__button,
    .c-control-group__field {
        max-width: calc(100% / 3);
        flex-basis: calc(100% / 3);;
    }
}

.t-basket__product-remove {
    border: none;
    width: 18px;
    height: 18px;
    line-height: 18px;
    padding: 0;
}

.t-basket__product-summary .c-table-responsive__row {
    max-height: 560px;
}
.c-table-responsive__cell.mobile {
    position: relative;
    top: -60px;
    left: 90%;
}

.t-basket_coupon-form,
.t-checkout-log-in__form {
    margin-bottom: 0;
}

.t-basket_coupon-form[aria-hidden='false'],
.t-checkout-log-in__form[aria-hidden='false'] {
    min-height: 3rem;
    max-width: 500px;
    margin: 1rem auto;

    input {
        margin-bottom: 12px;
    }
}


.cart-item-name {
    margin-bottom: 10px;
}


.order-summary-header {
    border-bottom: $borderBase;
    margin-bottom: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .h2 {
        margin-bottom: 0;
    }
    @include media-query($large-up) {
        padding: 25px 45px 20px;
    }
}

.totals-line {
    padding: 15px 0;
    border-bottom: 1px solid rgba(188, 189, 192, 0.5);
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 10px;
    }
}

.order-summary-totals {
    // padding: 0 15px;
    // @include media-query($large-up) {
    //     padding: 0 45px;
    // }
    form {
        margin-bottom: 0;
    }
}

.order-summary-actions {
    border-bottom: $borderBase;
    padding: 25px 0;
    margin-bottom: 10px;
}

.helpful-info {
    padding: 15px 0;
    line-height: 1.5;
}
