/* ==========================================================================
EXTENSIONS / PRODUCT LAYOUT / PRODUCT LAYOUT
========================================================================== */

@use "sass:math";

/**
* This is the default product layout for the product page.
*/
.t-main-product-page {
    background-color: $color-lightest-gray;
}

.x-product-layout {
    @apply relative;
}

@media (min-width: 60em) {
    .x-product-layout {
        @apply mt-8;
    }
}

$thumbnailItemWidth: 103px;
.product-image-gallery {
    img {
        margin: auto;
        display: block;
        width: 100%;
    }
    .main-carousel {
        margin-bottom: 25px;
        .slick-arrow {
            &::before, &::after {
                content: none;
            }
            .icon {
                height: 45px;
                color: inherit;
            }
            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }
    .thumbnail-carousel-wrapper {
        &.items-1 {
            max-width: $thumbnailItemWidth;
        }
        &.items-2 {
            max-width: $thumbnailItemWidth * 2;
        }
        &.items-3 {
            max-width: $thumbnailItemWidth * 3;
        }
        &.items-4 {
            max-width: $thumbnailItemWidth * 4;
        }
        &.items-5 {
            max-width: $thumbnailItemWidth * 5;
        }
        &.items-6 {
            max-width: $thumbnailItemWidth * 6;
        }
    }
    .thumbnail-carousel {
        .slick-track {
            margin-left: 0;
        }
        .slick-slide {
            margin-right: 13px;
            width: 90px!important;
            .single-thumbnail {
                position: relative;
                cursor: pointer;
                width: 90px !important;
                padding: 10px;
                display: block !important;
                img {
                    width: 70px;
                    max-height: 70px;
                    width: auto;
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0px;
                    height: 1px;
                    background-color: transparent;
                }
            }
            &.slick-current {
                .single-thumbnail::after {
                    background-color: $color-secondary;
                }
            }
        }
    }
}

.x-product-layout-images {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    .swiper-pagination-bullet {
        &:last-child {
            margin-right: 0!important;
        }
    }
    .swiper-pagination-bullet-active {
        position: relative;
        top: 10px;
        @include media-query($medium-up) {
            top: 0;
            transition: top 0.5s;
        }
    }
    @include media-query($medium-up) {
        &:hover {
            .thumbnailSwiper {
                height: 35px;
            }
            .swiper-pagination-bullet-active {
                top: 10px;
            }
        }
    }

    // on medium up screen, hover to show the arrow icon
    @include media-query($medium-up) {
        &:hover {
            .swiper .swiper-button-prev {
                left: 10px;
            }
            .swiper .swiper-button-next {
                right: 10px;
            }
        }
        .swiper {
            .swiper-button-prev {
                left: -36px;
                transition: left 0.5s;
            }
            .swiper-button-next {
                right: -36px;
                transition: right 0.5s;
            }
        }
    }

    .mainSwiper {
        border-radius: 36px;
        background-color: $color-white;
    }
    .thumbnailSwiper {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(calc(-50% + 5px));
        height: 35px;
        @include media-query($medium-up) {
            height: 0;
            transition: height 0.5s;
        }


        .swiper-wrapper {
            justify-content: center;
            margin-left: 10px;
            margin-bottom: 0;
        }
        .swiper-slide {
            width: 48px!important;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 3px;
                border: 1px solid $color-copy;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.x-product-layout-images__image {
    display: inline-block;
    margin-bottom: calc(#{$global-spacing-unit} * 2);
}

.x-product-layout-images__thumbnail-list {}

.x-product-layout-images__thumbnail-image {
    margin-bottom: $global-spacing-unit;
}


.x-product-layout-purchase {
    @apply mb-4 lg:mb-12;
}

.x-product-layout-details .wishlist-button {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    color: #273A3F;
    .icon {
        width: 22px;
        height: 19px;
        margin-right: 6px
    }
}
.x-product-layout-purchase__name {
    @apply mb-1;
}

.x-product-layout-purchase__reviews {
    @apply mb-4;
}

.x-product-layout-purchase__pricing {
    @apply mb-8;
}

// .x-product-layout-purchase__pricing-original,
// .x-product-layout-purchase__pricing-current {
//     font-size: 1.125rem;
// }

.x-product-layout-purchase__pricing-original {
    @apply h4 self-baseline text-grayscale;
}

.x-product-layout-purchase__pricing-current {
    @apply h3 self-baseline;
}

.x-product-layout-purchase__pricing-original:not(:empty) {
    @apply ml-5 line-through;
}

.x-product-layout-purchase__product-discounts {
    @apply hidden;
}

.x-product-layout-purchase__volume-pricing:empty {
    @apply hidden mb-0;
}

.x-product-layout-purchase__inventory-message:empty {
    @apply hidden;
}

.x-product-layout-purchase__options {
    @apply w-auto max-w-none;
}

.x-product-layout-purchase__options-attribute {
    @apply w-full my-3 lg:my-6;

    &--swatches {
        @apply my-2;
    }

    &--swatches + input + &:not(&--swatches) {
        @apply mt-4 lg:mt-10;
    }

    &--group {
        @apply mb-4 lg:mb-8;

        legend {
            @apply not-sr-only mb-1 font-menu;
        }
    }

    &--group & {
        @apply my-0;
    }
}

// .x-product-layout-purchase__options-attribute .c-form-label {
//     font-size: 0.75rem;
// }

.x-product-layout-purchase__options-quantity {
    @apply flex items-stretch relative;

    .x-quantify {
        @apply absolute left-0 z-10 top-0 bottom-0;

        .c-form-select {
            @apply w-full h-full;
        }
        .c-form-select__dropdown {
            @apply w-full h-full rounded-r-none rounded-lg pr-8 bg-transparent text-white border-transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.435 0.5L0.56501 0.5C0.38177 0.5 0.205279 0.587273 0.100035 0.743117C-0.0387078 0.948831 -0.0309582 1.2187 0.109784 1.41403L5.05952 8.27117C5.16377 8.41506 5.32701 8.5 5.5 8.5C5.67299 8.5 5.83623 8.41506 5.94048 8.27117L10.8902 1.41403C11.031 1.2187 11.0387 0.948831 10.9 0.743117C10.7947 0.587273 10.6182 0.5 10.435 0.5Z' fill='%23fff'/%3E%3C/svg%3E%0A");
            background-size: 11px 7px;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .button-wrap {
        @apply relative flex-1;

        .button {
            @apply font-bold text-xl md:text-2xl;
        }
    }
}


.x-product-layout-purchase__swatches {
    padding-top: 3px;

    ul {
        @apply flex flex-wrap -mx-1 mb-0;
    }

    .o-list-inline__item {
        @apply align-middle px-1 m-0 mb-2;
    }

    button {
        @apply p-0 border-none bg-transparent w-10 rounded-full overflow-hidden;
    }

    &--active {
        @apply ring-1 ring-offset-[3px];
        // box-shadow: 0 0 0 2px $color-lightest-gray, 0 0 3px 3px $color-light-gray;
    }

    &.has-color ul .o-list-inline__item,
    &.has-image ul .o-list-inline__item {
        @apply flex-none;
    }

    .swatch-rectangle {
        @apply flex-1 basis-1/3 max-w-1/3;

        button {
            @apply w-full font-menu leading-[1.15em] block text-center border border-grayscale-lightGrey text-copy-primary bg-copy-tertiary py-3 m-0 rounded-lg transition-colors before:hidden after:hidden;
        }

        &.selected button,
        button:hover {
            @apply text-copy-tertiary bg-copy-primary border-copy-primary shadow-none;
        }

        button:hover {
            @apply bg-opacity-90;
        }

        &.selected button {
            @apply bg-opacity-100 before:hidden after:hidden;
        }
    }
}

.add-on-title {
    margin-bottom: 8px;
}
.product-add-on {
    list-style: none;
    @apply ml-0;

    .add-on-item {
        @apply w-auto cursor-pointer text-base -ml-1.5;
        &:hover {
            cursor: pointer;
        }
        .add-on-name {
            @apply inline-flex items-center relative p-1.5 select-none h-full text-base font-bold
                before:content-[''] before:inline-block before:relative before:w-5 before:h-5 before:transition-colors before:bg-white before:rounded-md before:mr-1.5 before:border-2 before:border-copy before:flex-shrink-0
                after:content-[''] after:block after:absolute after:w-3 after:h-3 after:transition-colors after:bg-primary after:rounded-[3px] after:top-1/2 after:left-2.5 after:-translate-y-1/2 after:scale-0 after:opacity-0;
        }

        &.active {
            .add-on-name {
                @apply before:transition-colors after:block after:scale-100 after:opacity-100;
            }
        }
    }
}

.x-product-layout-purchase__subtotal {
    @apply mb-4 lg:mb-10;
}

.x-product-layout-purchase__builder-confirmation {
    @apply mb-2.5;

    .c-form-checkbox__caption {
        @apply font-body
                before:w-3 before:h-3 before:rounded-[3px]
                after:w-1.5 after:h-1.5 after:left-[9px] after:rounded-[1px];
        align-items: flex-start;

        &::before {
            margin-top: 3px;
        }

        &::after {
            top: 15px;
        }
    }
}

.x-product-description {

}

.info-button {
	width: 17px;
	height: 17px;
	vertical-align: super;
    display: inline-block;
    -webkit-appearance: none;
	.icon {
		width: 17px;
		height: 17px;
	}
}
.add-on-modal {
	display: flex;
	background-color: $color-white;
	padding: 40px;
	border-radius: 36px;
	width: 938px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	@include media-query($medium-down) {
		flex-direction: column;
	}
	.close-button {
		position: absolute;
		top: 40px;
		right: 40px;
		@include media-query($small-down) {
			top: 20px;
			right: 20px;
		}
	}
	img {
		max-width: 550px;
		border-radius: 24px;
		@include media-query($large-up) {
			margin-right: 40px;
		}
		@include media-query($medium-down) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.add-on-content {
		align-self: flex-end;
	}
}


.accordion-section {
    @apply bg-white mb-5 rounded-lg;

    .accordion {
        @apply h4 mb-0 capitalize w-full text-left bg-white text-copy px-6 py-5 h-auto flex items-center
                lg:px-12 lg:py-7
                hover:bg-white hover:text-primary hover:fill-primary;

        .icon {
            @apply w-2.5 h-2.5 -ml-[14px] mr-1
                    lg:mr-2.5 lg:-ml-5;
        }

        .icon-arrow-down {
            @apply hidden;
        }

        &.active {
            .icon-arrow-down {
                @apply block;
            }

            .icon-arrow-right {
                @apply hidden;
            }
        }
    }

    .panel {
        @apply mt-1.5 pb-1.5 mx-6 xl:mt-2.5 xl:pb-2.5 xl:mx-12;
    }
}

// .x-product-layout-add-on {
//     .c-form-checkbox__caption {
//         @apply font-bold text-base;
//         svg {
//             @apply ml-1;
//         }
//     }
// }

.x-product-layout-purchase__options-attribute--swatches {
    position: relative;
    .error-message {
        color: $color-error;
        position: absolute;
        font-weight: 400;
        font-size: 13px;
        right: 0;
        top: 0;
        p {
            margin: 0;
        }

        @include media-query($xsmall) {
            position: static;
        }
    }
}
