/* ==========================================================================
COMPONENTS / BUTTONS
========================================================================== */

/**
* 1.  Allows styling of box model properties.
* 2.  Reset browser styles.
* 3.  Subtract border-width from the padding so buttons don't grow if the
*     border-width is modified.
* 4.  Tidy alignment for when the button size needs to be modified.
* 5.  Make buttons inherit font styles (necessary when styling `input`s as
*     buttons).
* 6.  Set default button font-size.
* 7.  Set default button font-weight.
* 8.  Set default button text-alignment.
* 9.  Remove anchor text-decoration (necessary when styling `a`s as buttons).
* 10. Base transparent border for modifiers to alter.
* 11. Set default button border-radius.
* 12. Set default button transition (color, background-color, border-color and box-shadow)
* 13. Force all button-styled elements to appear click-able.
*/

.button {
	display: inline-block; /* [1] */
	margin: 0; /* [2] */
	padding: 0 40px;
	position: relative;
	vertical-align: middle; /* [4] */
	font-family: inherit; /* [5] */
	font-size: 16px; /* [6] */
	font-weight: 800; /* [7] */
	line-height: $button-height-default;
	height: $button-height-default;
	text-align: center; /* [8] */
	text-decoration: none; /* [9] */
	color: $buttonStyle-default-color;
	background-color: $buttonStyle-default-backgroundColor;
	border: $global-border-width solid rgba(0, 0, 0, 0); /* [10] */
	border-radius: 8px; /* [11] */
	appearance: none;
	cursor: pointer; /* [13] */
	text-transform: uppercase;
	transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* [12] */
	user-select: none;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [9] */
	}

	&:not([class*="u-bg-"]):hover{
		background-color: $color-light-gray--shaded;
	}

	// Primary styles go here
	&.button--primary {
		color: $buttonStyle-default-color;
		background-color: $buttonStyle-default-backgroundColor;

		&:hover {
			border-color: $buttonStyle-default-backgroundColorHover;
			color: $buttonStyle-default-colorHover;
			background-color: $buttonStyle-default-backgroundColorHover;
		}
	}

	// Secondary styles go here
	&.button--secondary {
		background-color: $color-secondary;
		border: none;
		color: $color-copy;

		&:hover {
			color: $buttonStyle-default-colorHover;
			background-color: $buttonStyle-default-backgroundColorHover;
		}
	}

	// Tertiary styles go here
	&.button--tertiary {

	}

	/* Button sizing modifiers
	=========================================== */
	&.button--tiny {
		padding: 0 calc(0.5em - #{$global-border-width}); /* [3] */
		font-size: 0.625em;
		height: $button-height-xsmall;
		line-height: $button-height-xsmall;
	}

	&.button--small {
		padding: 0 calc(0.667em - #{$global-border-width}); /* [3] */
		font-size: 0.75em;
		height: $button-height-small;
		line-height: $button-height-small;
	}

	&.button--large {
		padding: 0 calc(2em - #{$global-border-width}); /* [3] */
		height: $button-height-large;
		line-height: $button-height-large;
	}

	&.button--huge {
		padding: 0 calc(3em - #{$global-border-width}); /* [3] */
		height: $button-height-huge;
		line-height: $button-height-huge;
	}

	&.button--flush {
		padding-right: 0;
		padding-left: 0;
	}
}

/**
* Fix a Firefox bug whereby `input type="submit"` gains 2px extra padding.
*/
.button::-moz-focus-inner {
	border: 0;
	padding: 0;
}


/**
* Button Icon
*
* This enables an icon to be placed to the **left** of a button's text. The
* icon's colour will be inherited from the component's `color` attribute unless
* the icon has an explicit `fill` property set.
*
* Note: To avoid spacing issues, do *not* add an extra space character between
*       your button text and image tag.
*
* 1. Offset icon from the text baseline to be centered within
the component.
* 2. Icons must always conform to a set width and height.
* 3. Icon should inherit it's fill from the component's text colour.
*/
.button__icon {
	width: 16px; /* [2] */
	height: 16px; /* [2] */
	margin-right: 8px;
	position: relative; /* [1] */
	top: 4px; /* [1] */
	line-height: inherit; /* [1] */
	vertical-align: top; /* [1] */
	fill: currentColor; /* [3] */
}

/**
* Button Icon - Right
*
* This enables an icon to be placed to the **right** side of a button's text.
*/
.button__icon--right {
	margin-right: 0;
	margin-left: 8px;
}


/* Button aesthetic modifiers
=========================================== */
/**
* For outlined or hollow buttons.
*/
.button--hollow {
	background-color: rgba(0, 0, 0, 0);
	border-color: currentColor;
	color: $color-light-gray;
}

.button--hollow:not([class*="u-bg-"]):not([class*="u-color-"]):hover{
	background-color: $color-light-gray;
}


/**
* For buttons you want to look like normal links.
*/
.button--clear {
	padding: 0;
	background-color: rgba(0, 0, 0, 0);
	vertical-align: baseline;
	color: $color-copy-secondary;
}

.button--clear:not([class*="u-bg-"]):hover{
	background-color: rgba(0, 0, 0, 0);
}


/* Button state modifiers
=========================================== */

/**
* For buttons whose state is toggled.
*/
.button:disabled,
.button.is-disabled,
.button.is-disabled:hover,
.button.is-disabled:active,
.button.is-disabled:focus {
	box-shadow: none;
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}


/**
* For buttons whose state is toggled when a related form or field contains
* an error or multiple errors.
*/
.button.is-error,
.button.is-error:hover,
.button.is-error:active,
.button.is-error:focus,
.is-error .button,
.is-error .button:hover,
.is-error .button:active,
.is-error .button:focus {
	background-color: $color-error;
	border-color: $color-error--shaded;
	color: $color-white;
	cursor: not-allowed;
}


/**
* For buttons that need to display full-width on small devices only.
*/
.button--full-small {
	display: block;
	width: 100%;
}

/**
* For buttons that need to display full-width.
*/
.button--full {
	display: block;
	width: 100%;
}

.button--quantity {
	background-color: $color-white;
	color: $color-primary;
	padding: 5px 0;

	&:hover,
	&:focus,
	&:active {
		background-color: $color-primary;
		color: $color-white;
	}

	svg {
		pointer-events: none;
		vertical-align: unset;
	}
}

/**
* For Apple Pay buttons
*/
.apple-pay-button-with-text {
	width: 100%;
	margin-top: 10px;
}
