/* ==========================================================================
THEME STYLE MODIFICATIONS
========================================================================== */

/**
* Set all your THEME styles in this file.
*
* Adding a Theme to a design is very straightforward as every component works
* like an extensible class. This allows you to add theme specific classes
* and/or override existing classes.
*
* NOTE: Any new classes created should follow the established coding
* standards for the framework. Theme specific classes should be prefixed by
* `.t-`.
*
* Why do it this way?
* By decoupling the theme styling from the default framework, you get the
* following benefits:
*		Keep the framework core library up-to-date
*		Updates to the core styles of the framework do not get overwritten when
*		extending the component.
*
*		End-user entry points remain untouched
*		End-users do not need to use any custom name that belongs only to the
*		theme.
*/

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "functions";
@import "mixins";
@import "variables";
@import "components/vendor/timber/grid";
@import '~@selectize/selectize/src/scss/selectize';

@import "components/core";
@import "extensions/extensions";
@import "pages/pages";

@layer base {
    body {
        @apply text-left;
    }
}
