/* ==========================================================================
   COMPONENTS / SLICK-SLIDER
   ========================================================================== */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
        padding: 0;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.slick-arrow {
    appearance: none;
    background: transparent;
    border: 0 none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color-secondary;
    z-index: 2;
    cursor: pointer;
    font-size: 0;
    &::before {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
        height: 41px;
        width: 20px;
        content: '';
    }

    &:hover,
    &:focus {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    // .icon {
    //    width: 20px;
    //    height: 15px;
    // }
    &.slick-disabled {
        opacity: 0.25;
        color: #999999;
    }
}


.slick-next {
    right: -15px;

    @include media-query($large-up) {
        right: -30px;
    }

    &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNCA0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS40NTY3OSAxLjAwNzQ2MTQ3bDIxIDIwLjAyNDgyMTQzTDEuNTA4ODUgNDEuMDA3NDYxNSIgc3Ryb2tlPSIjMkQyRDJEIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
    }

    &:hover {
        &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNCA0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS40NTY3OSAxLjAwNzQ2MTQ3bDIxIDIwLjAyNDgyMTQzTDEuNTA4ODUgNDEuMDA3NDYxNSIgc3Ryb2tlPSIjOTg5ODk4IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
        }
    }
}

.slick-prev {
    left: -15px;

    @include media-query($large-up) {
        left: -30px;
    }

    &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNCA0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjIuNDU3MjA3NCAxLjAwNzQ2MTQ3bC0yMSAyMC4wMjQ4MjE0MyAyMC45NDc5Mzk3IDE5Ljk3NTE3ODYiIHN0cm9rZT0iIzJEMkQyRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
    }

    &:hover {
        &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAyNCA0MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjIuNDU3MjA3NCAxLjAwNzQ2MTQ3bC0yMSAyMC4wMjQ4MjE0MyAyMC45NDc5Mzk3IDE5Ljk3NTE3ODYiIHN0cm9rZT0iIzk4OTg5OCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
        }
    }
}



.slick-dots {  // 2
    @include translate(-50%, 0);
    margin: 0;
    width: auto;
    left: 50%;
    white-space: nowrap;
    font-size: 0;
    bottom: 20px;
    position: absolute;
    display: flex;

    li {
        width: auto;
        height: auto;
        margin: 0;
        &:not(:last-of-type) {
            margin-right: 20px;
        }

        button {
            // scss-lint:disable NestingDepth
            border-radius: 50%;
            border: 1px solid $slick-dot-color;
            display: block;
            margin: 0;
            padding: 0;
            position: relative;
            text-align: center;
            width: $slick-dot-size;
            height: $slick-dot-size;
            background: $slick-dot-color;
            opacity: $slick-opacity-not-active;
            // border-radius: 50%;
            &::before {
                display: none;
            }

            &:hover {
                background: $slick-dot-color;
                opacity: $slick-opacity-on-hover;
            }
        }

        button {
            display: block;
            transition: background 100ms ease-out;
        }

        &.slick-active {
            button {
                // background: $slick-dot-color-active;
                opacity: $slick-opacity-default;
            }
        }
    }
}
