/* Account Landing Page
=========================================== */
.t-account-landing-section {
	margin-bottom: 1rem;
}

.t-account-landing-section .x-category-tree__label {}


@media (min-width: 48em) {
	.t-account-landing-section .x-collapsible-content__toggle {
		display: none;
	}

	.t-account-landing-section .x-collapsible-content__item {
		max-height: none;
		opacity: 1;
		overflow: visible;
		visibility: visible;
	}

}

.t-account-landing-section__content {
	padding-top: 1.25rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
}

.t-account-landing-section__content.x-category-tree__row {
	padding-left: 1rem;
}

.t-account-landing-section__content.x-category-tree__row span.x-category-tree__link:hover {
	text-decoration: none;
}

