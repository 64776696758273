/*
This File Contains a lot of the base styles from Shadows that we wanted to keep
*/
/* ==========================================================================
ELEMENTS / PAGE
========================================================================== */

/**
* High-level, page-level styling.
*
* 1. Ensure the page always fills at least the entire height of the viewport.
* 2. Force scroll bars to always be visible to prevent awkward 'jumps' when
*    navigating between pages that do/do not have enough content to produce
*    scroll bars naturally.
* 3. Set the default 'font-size' and 'line-height' for the entire project,
*    sourced from our default variables. The 'font-size' is calculated to exist
*    in ems.
* 4. Fonts on OSX will look more consistent with other systems that do not
*    render text using sub-pixel anti-aliasing.
* 5. Prevent certain mobile browsers from automatically zooming fonts.
* 6. Set the, non-standard, color of the highlight that appears over a link
*    when it is being tapped in some browsers.
* 7. https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action
*/

@use "sass:math";

html {
    height: 100%;
    min-height: 100%; /* [1] */
    overflow-y: scroll; /* [2] */
    font-size: 1em; /* [3] */
    -webkit-text-size-adjust: 100%; /* [5] */
    -ms-text-size-adjust: 100%; /* [5] */
    color: #4a4a4a;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: manipulation; /* [7] */
}


body {
    @apply min-h-full font-body font-sans antialiased;
}

/* ==========================================================================
UTILITIES / BORDERS
========================================================================== */

/**
* Utility classes to adjust element borders.
*/
.u-border-none {
    border-width: 0;
}

.u-border-thick {
    border-width: calc(#{$global-border-width} * 2);
}

.u-border-rounded {
    border-radius: 10em;
}

.u-border-square {
    border-radius: 0;
}


.u-over-everything {
    z-index: 500;
}

/* ==========================================================================
UTILITIES / CLEAR FIX
========================================================================== */

/**
* Class-based implementation of the 'clearfix hack'.
* http://cssmojo.com/the-very-latest-clearfix-reloaded/
*/
.clearfix::after {
    display: table;
    clear: both;
    content: "";
}

/* ==========================================================================
COMPONENTS / KEYLINE
========================================================================== */

/**
* Simple keyline component to provide horizontal rules between other elements.
* e.g. `<hr class="c-keyline">`
*
* 1. Reduce the `margin-bottom` by the same width as the keyline in order to
*    keep on our baseline grid.
*/
.c-keyline {
    margin-bottom: calc(#{$global-spacing-unit} - #{$global-border-width}); /* [1] */
    border: none;
    border-bottom: $global-border-width solid $color-lightest-gray;
}


/**
* Heavier keylines.
*/
.c-keyline--thick {
    margin-bottom: calc(#{$global-spacing-unit} - (#{$global-border-width} * 2)); /* [1] */
    border-bottom-width: calc(#{$global-border-width} * 2);
}


/**
* Smaller gaps underneath keylines.
*/
.c-keyline--small {
    margin-bottom: calc(#{$global-spacing-unit} / 2 - #{$global-border-width}); /* [1] */
}

/**
* Adjust smaller gaps underneath thicker keylines.
*/
.c-keyline--small.c-keyline--thick {
    margin-bottom: calc(#{$global-spacing-unit} / 2 - (#{$global-border-width} * 2)); /* [1] */
}

/* ==========================================================================
ELEMENTS / LINKS
========================================================================== */

/**
* Our basic A elements only need very minimal styling. Anything more
* opinionated (e.g. buttons, calls-to-action, etc.) will need a class defining
* in the Components layer.
*/

a {
    color:  $link-color;
    text-decoration: none;
    cursor: pointer;
}

a:active,
a:focus,
a:hover {
    text-decoration: underline solid  $link-hover-underline-color;
}

/* ==========================================================================
ELEMENTS / MEDIA
========================================================================== */

/**
* 1. Fluid media for responsive purposes.
* 2. Setting 'vertical-align' removes the whitespace that appears under 'img'
*    elements when they are dropped into a page as-is. Safer alternative to
*    using 'display: block;'.
* 3. Progressive enhancement for better loading and CLS reduction:
*		https://web.dev/optimize-cls/
*		https://drafts.csswg.org/css-sizing-4/#ratios
*/

audio,
video {
    width: 100%;
}

audio:focus,
video:focus {
    outline: 1px dotted currentColor;
}

embed,
img,
object,
video {
    max-width: 100%; /* [1] */
    height: auto; /* [1] */
}

iframe {
    max-width: 100%; /* [1] */
}

embed,
object {
    height: 100%;
}

img {
    vertical-align: middle; /* [2] */
    width: 100%;
    aspect-ratio: calc(attr(width) / attr(height)); /* [3] */
}

/* ==========================================================================
COMPONENTS / MENU
========================================================================== */

.c-menu {}

.c-menu__title {
    font-weight: 700;
}

.c-menu__list {
    margin-left: 0;
    line-height: 2;
    list-style: none;
}

.c-menu__row {}

.c-menu__link {
    display: block;
    color: currentColor;
}

@media (min-width: 48em) {
    .c-menu__link {
        display: inline-block;
    }
}

// =============================================================================
// THEME FOCUS (global)
// =============================================================================
input,
button,
textarea,
select,
details,
[href],
[tabindex]:not([tabindex="-1"]),
[contenteditable="true"] {
    &:focus {
        outline: $outline-width $outline-style $outline-color !important;
        outline-offset: $outline-offset !important;
        border: 0px;
    }
}

label {
    input:focus + & {
        @apply outline-none outline-blue-600;
        // outline: $outline-width $outline-style $outline-color;
        // outline-offset: $outline-offset;
    }
}
