/* Main Element
=========================================== */
.t-main-content-element {
    @apply pb-5;
}

.t-page-prod {
    .t-main-content-element {
        @apply bg-background;
    }
}

.t-page-ctgy,
.t-page-srch {
    background-color: $color-lightest-gray;
}

.t-page-srch {
    main {
        min-height: 40vh;
    }
}

.page-heading {
    @apply pt-5 mb-5 text-center md:pt-10 md:mb-10;
}

.t-page-ctus {
    address {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.t-page-logn {
    .forgot-password {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
