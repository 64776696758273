/* ==========================================================================
EXTENSIONS / MESSAGES
========================================================================== */

/**
* Messages are block elements used to display information to the visitor about
* their current session. Typically these are informational or error messages
* such as you would see on a checkout page if there was an error with the
* credit card used.
*/

.x-messages {
	margin: $global-spacing-unit 0;
	padding: 0.75em 1.25em;
	font-size: 0.9rem;
	background-color: $color-lightest-gray;
	border: 1px solid $color-light-gray;
	line-height: 1.5;
	border-radius: $global-border-radius;
}

.x-messages--borderless {
	border: 0 none;
}

.x-messages--error {
	background-color: #f8d7da;
	border-color: #f5c6cb;
	color: $color-error;
}

.x-messages--info {
	color: $color-info;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}

.x-messages--success {
	background-color: $color-white;
	color: $color-success;
}

.x-messages--warning {
	color: $color-warning;
	background-color: #fff3cd;
	border-color: #ffeeba;
}


/**
* These classes will help format the message if you are adding an icon.
*/
.x-messages--with-icon {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.x-messages__icon {
	font-size: 3rem;
}


.x-messages__close {
	position: absolute;
	top: 50%;
	right: 0.25rem;
	margin-top: -1rem;
	color: $color-tertiary;
	font-size: 2rem;
	line-height: 1;
	text-decoration: none;
	opacity: 0.3;
}
