
/* ==========================================================================
SETTINGS / GLOBAL
Set all your global CSS variables in this file.
Overrides should be placed in the 'ui/css/theme.scss' file.
========================================================================== */

/* theme settings
=========================================== */
@use "sass:math";

$theme-path: "themes/00000001/shadows" !default;

/* border settings
=========================================== */
$global-border-width: 1px !default;
$global-border-radius: 1px !default;

$borderBase: 1px solid #dedede;

/* breakpoints
=========================================== */
$breakpoint-small: 640 !default;
$breakpoint-medium: 768 !default;
$breakpoint-large: 960 !default;
$breakpoint-x-large: 1200 !default;
$breakpoint-wide: 1440 !default;


/* miscellaneous
=========================================== */
$global-spacing-unit: 16px !default;


/**
* Colors
*
* This section sets default theme for the project so the variables exist.
*/
$color-black: #000 !default;
$color-light-gray: #CACCCE !default;
$color-lightest-gray: #F8F9FA !default;
$color-white: #fff !default;
$color-transparent: transparent !default;
$color-copy: #273A3F !default;
$color-copy-secondary: #4C5A5E !default;
$color-primary: #1EC0C9 !default;
$color-secondary: #ECD19A !default;
$color-tertiary: #EACDC6 !default;
$color-quaternary: #757779 !default;
$color-quinary: #F2F7F8 !default;
$color-info: #0c5460 !default;
$color-success: #0d8670 !default;
$color-warning: #856404 !default;
$color-error: #ee0020 !default;
$shade-tint-percent: 25% !default;
$link-color: $color-copy !default;
$link-underline-color: #8cc8ff !default;
$link-visited-color: #556dc6 !default;
$link-visited-underline-color: #a9b6e3 !default;
$link-hover-underline-color: #006fc6 !default;
$hero-sfnt-color: #E2F4F5 !default;
$color-dark-gray: #999999 !default;

/**
* This section sets shading and tinting variables to be used for events,
* like hover, for the project.
*/
$color-black--tinted: tint($color-black, $shade-tint-percent);
$color-tertiary--shaded: shade($color-tertiary, $shade-tint-percent);
$color-tertiary--tinted: tint($color-tertiary, $shade-tint-percent);
$color-light-gray--shaded: shade($color-light-gray, $shade-tint-percent);
$color-light-gray--tinted: tint($color-light-gray, $shade-tint-percent);
$color-light-gray--shaded: shade($color-light-gray, $shade-tint-percent);
$color-light-gray--tinted: tint($color-light-gray, $shade-tint-percent);
$color-light-gray--shaded: shade($color-light-gray, $shade-tint-percent);
$color-light-gray--tinted: tint($color-light-gray, $shade-tint-percent);
$color-lightest-gray--shaded: shade($color-lightest-gray, $shade-tint-percent);
$color-lightest-gray--tinted: tint($color-lightest-gray, $shade-tint-percent);
$color-white--shaded: shade($color-white, $shade-tint-percent);
$color-primary--shaded: shade($color-primary, $shade-tint-percent);
$color-primary--tinted: tint($color-primary, $shade-tint-percent);
$color-secondary--shaded: shade($color-secondary, $shade-tint-percent);
$color-secondary--tinted: tint($color-secondary, $shade-tint-percent);
$color-tertiary--shaded: shade($color-tertiary, $shade-tint-percent);
$color-tertiary--tinted: tint($color-tertiary, $shade-tint-percent);
$color-error--shaded: shade($color-error, $shade-tint-percent);

/* ==========================================================================
SETTINGS / TYPOGRAPHY
This is where the global typography measurements are set.
Overrides should be placed in the 'ui/css/theme.scss' file.
========================================================================== */

/* typography settings
=========================================== */
$global-font-size: 14px;
// We use these to define default font stacks
// $fallback-stack-sans-serif:        Helvetica, Arial, sans-serif;
// $fallback-stack-serif:             Georgia, Times, serif;

$body-font-family:              theme('fontFamily.sans');
$header-font-family:            theme('fontFamily.sans');
$header-font-color:             theme('colors.copy.primary');
$lineHeight-base: 1.2;
$body-font-weight: 400;
$fontSize-base: $global-font-size;

$min-font-scale: 1.2 !default; /* Minor Third Font Scale */
$max-font-scale: 1.333 !default; /* Perfect Fourth Font Scale */
$min-line-height: 1.5 !default;
$max-line-height: 1.8 !default;
$fluid-line-height: calc((#{$min-line-height} + #{$max-line-height}) / 2) !default;


$screen-compensation: calc((100vw - (#{$breakpoint-small} * 1px)) / (#{$breakpoint-large} - #{$breakpoint-small})) !default;
$echo: $global-font-size !default;
$foxtrot-min: round(math.div($echo, $min-font-scale)) !default;
$foxtrot-max: round(math.div($echo, $max-font-scale)) !default;
$delta-min: round($echo * $min-font-scale) !default;
$delta-max: round($echo * $max-font-scale) !default;
$charlie-min: round($delta-min * $min-font-scale) !default;
$charlie-max: round($delta-max * $max-font-scale) !default;
$bravo-min: round($charlie-min * $min-font-scale) !default;
$bravo-max: round($charlie-max * $max-font-scale) !default;
$alpha-min: round($bravo-min * $min-font-scale) !default;
$alpha-max: round($bravo-max * $max-font-scale) !default;

$heading-alpha-min: $alpha-min !default;
$heading-alpha-max: $alpha-max !default;
$heading-alpha-fluid: calc(#{$heading-alpha-min} + (#{$alpha-max} - #{$alpha-min}) * #{$screen-compensation}) !default;
$heading-bravo-min: $bravo-min !default;
$heading-bravo-max: $bravo-max !default;
$heading-bravo-fluid: calc(#{$heading-bravo-min} + (#{$bravo-max} - #{$bravo-min}) * #{$screen-compensation}) !default;
$heading-charlie-min: $charlie-min !default;
$heading-charlie-max: $charlie-max !default;
$heading-charlie-fluid: calc(#{$heading-charlie-min} + (#{$charlie-max} - #{$charlie-min}) * #{$screen-compensation}) !default;
$heading-delta-min: $delta-min !default;
$heading-delta-max: $delta-max !default;
$heading-delta-fluid: calc(#{$heading-delta-min} + (#{$delta-max} - #{$delta-min}) * #{$screen-compensation}) !default;
$heading-echo-fluid: calc(#{$echo} * #{$screen-compensation}) !default;
$heading-foxtrot-min: $foxtrot-min !default;
$heading-foxtrot-max: $foxtrot-max !default;
$heading-foxtrot-fluid: calc(#{$heading-foxtrot-min} + (#{$foxtrot-max} - #{$foxtrot-min}) * #{$screen-compensation}) !default;


/**
* Grid sizes
*/

$grid_small: 480px;
$grid_medium: 768px;
$grid_large: 991px;
$grid_xlarge: 1200px;
$grid_widescreen: 1400px;

$mobile-width: 400px;
$tablet-width: 768px;
$desktop-width: 991px;
$widescreen-width: 1400px;
$wrapper_width_default: 1439px;
$grid_gutter: 22px;

/**
* Buttons
*/
$button-height-xsmall:                       20px;
$button-height-small:                        30px;
$button-height-default:                      46px;
$button-height-large:                        60px;
$button-height-huge:                         70px;
$buttonStyle-default-color:                  $color-white;
$buttonStyle-default-colorHover:             $color-white;
$buttonStyle-default-backgroundColor:        $color-primary;
$buttonStyle-default-backgroundColorHover:   $color-copy;
$buttonStyle-default-borderColor:            $color-primary;
$buttonStyle-default-borderColorHover:       $color-copy;


// Slick Carousel
// -----------------------------------------------------------------------------

$slick-arrow-color:             $color-primary;
$slick-dot-color:               $color-primary;
$slick-dot-color-active:        $color-primary;
$slick-dot-size:                8px;
$slick-opacity-default:         1;
$slick-opacity-on-hover:        0.8;
$slick-opacity-not-active:      0.65;


// Focus States
$outline-width:  2px;
$outline-style:  solid;
$outline-color:  $color-primary;
$outline-offset: 0px;

// Icons
$icon-remove: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 13H5v-2h14v2z'/%3E%3C/svg%3E%0A";
$icon-add: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E%0A";
$icon-delete: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E%0A";
$icon-checkmark: "data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 9 7' xml:space='preserve'%3E%3Cpath d='M8.8,0.2c0.3,0.3,0.3,0.8,0,1.1L3.7,6.8c-0.3,0.3-0.8,0.3-1.1,0L0.2,4.2c-0.3-0.3-0.3-0.8,0-1.1 c0.3-0.3,0.8-0.3,1.1,0l1.9,2l4.5-4.8C8-0.1,8.5-0.1,8.8,0.2'/%3E%3C/svg%3E%0A";


// Selectize
// -----------------------------------------------------------------------------

$select-border-radius: 20px;
$select-font-size: theme('fontSize.base');
$select-font-family: theme('fontFamily.sans');
$select-line-height: theme('lineHeight.normal');
$select-padding-x: 1rem;
$select-padding-y: 0.75rem;
$select-padding-item-x: 1rem;
$select-padding-item-y: 0.375rem;
$select-padding-dropdown-item-x: 1rem;
$select-padding-dropdown-item-y: 0.375rem;
$select-margin-item-x: 0;
$select-margin-item-y: 0;
