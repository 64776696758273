@use "sass:math";

.site-navigation {
    position: relative;
    > .wrapper {
        position: static;
        padding: 0;

        @include media-query($xlarge-up) {
            padding: 0 22px;
        }
    }
}
.main-nav {
    ul {
        list-style: none;
        margin: 0;
    }
}

.mega-menu-list {
    display: flex;
    justify-content: space-around;
    @include media-query($xlarge-up) {
        justify-content: space-between;
    }
    .mega-menu-dropdown {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: $color-white;
        padding: 48px 0 30px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 90px 90px;
        border-top: 1px solid $color-light-gray;
        .dropdown-header {
            line-height: 1.5;
        }
    }
    .mega-menu-item-parent {
        line-height: math.div(21, 16);
        transition: 0.3s ease;
        &.has-submenu {
            .icon {
                margin-left: 2px;
                @include media-query($large-up) {
                    margin-left: 10px;
                }
            }
            &:hover {
                .icon {
                    transform: rotate(180deg);
                    margin-bottom: 2px;
                }
            }
        }
        &.is-active,
        &:hover {
            .mega-menu-dropdown {
                display: block;
            }
        }
        > span {
            color: $header-font-color;
            font-weight: 700;
            text-decoration: none;
            display: block;
            font-size: 15px;
            padding: 10px 0px;
            > a {
                text-decoration: none;
            }
            // @include media-query($large-up) {
            //     padding: 10px 20px;
            // }
        }
        .icon--chevron-down {
            fill: currentColor;
            height: 8px;
            width: 8px;
        }
        &.mega-menu-sale {
            transition: none !important;
            background-color: $color-secondary;
            border-radius: 12px 12px 0px 0px;
            color: $header-font-color;
            font-weight: 700;
            text-decoration: none;
            display: block;
            font-size: 15px;
            padding: 10px 0px;
            &:hover {
                background-color: $color-copy;
                > a {
                    text-decoration: none;
                    color: $color-white;
                }
            }
            > a {
                @include media-query($large-up) {
                    padding: 10px 20px;
                }
            }
        }
    }
    .mega-menu-item-child {
        margin-bottom: 18px;
        > a {
            color: $color-copy;
        }
        ul {
            margin-top: 18px;
            .mega-menu-item-grandchild {
                margin-bottom: 15px;
                > a {
                    color: $color-copy;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

    }
    .mega-menu-item-link {
        color: $color-copy;
        svg {
            height: 16px;
        }
    }
    .mega-menu-image {
        padding-top: 18px;
    }
}

.sidenav {
    margin-left: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 103;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    min-height: 450px;

    @include media-query($medium-up) {
        display: none;
    }

    .sidenav-header {
        // height: 45px;
        padding: 15px 20px;
        position: relative;
        border-radius: 0px 0px 36px 36px;
        background-color: $color-lightest-gray;

        .closebtn {
            color: $color-copy;
            background-color: transparent;
            line-height: 46px;
            padding: 0;
            z-index: 99;
            .icon--close {
                width: 18px;
                height: 18px;
            }
        }
        .t-site-header__masthead-links {
            > a, button {
                display: contents;
                @include media-query($medium-down) {
                    font-size: 13px;
                }
            }
            svg {
                margin-left: 20px;
                margin-right: 10px;
            }
            .t-site-header__basket-link-quantity {
                top: 7px;
                right: 14px;
            }
        }
    }

    .sidenav-footer {
        position: absolute;
        width: 100%;
        bottom: 0px;
        background-color: $color-copy;
        border-radius: 36px 36px 0 0;
        padding: 20px;
        .t-global-footer-social-links {
            max-width: 100%;
            ul {
                justify-content: space-evenly;
            }
        }
    }

    .icon--chevron-right,
    .icon--chevron-down {
        width: 8px;
        height: 5px;
    }

    .mobile-menu-open & {
        margin-left: 0px;
        transition: all 0.3s ease-in;
    }

    .mobile-menu__search-form {
        margin: 0;
        margin-left: 20px;
        border-radius: 40px;
        background-color: #fff;
        border: 1px solid $color-copy-secondary ;
        height: 40px;

        .c-form-input {
            height: 38px;
            line-height: 38px;
            padding: 0px;
            &::placeholder {
                color: $color-copy-secondary;
                font-weight: 600;
            }
        }

        .button {
            color: $color-light-gray;
            height: 40px;
            line-height: 40px;
            padding: 3px 11px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
    }

    .mobile-menu-item,
    .mobile-submenu-item {
        > a,
        > .button {
            color: $color-copy;
            // text-transform: uppercase;
            font-weight: 700;
            font-size: 15px;
        }
    }

    .mobile-menu-item {
        padding: 15px 20px;
        // border-bottom: 1px solid $color-light-gray;

        &.has-submenu {
            padding: 0;
        }

        &.mobile-menu-item-sale {
            > a {
                padding: 5px 0px;
                @extend .link-animated-border;
                &:before {
                    transition: none !important;
                    width: 100% !important;
                    background: $color-secondary !important;
                    margin-top: 5px;
                }
            }
        }
    }

    .mobile-menu--back,
    .mobile-menu--button {
        padding: 0 20px;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: $color-white;
        color: $color-copy-secondary;
        text-align: left;
        height: 50px;
        text-transform: capitalize;
        font-weight: 700;
    }

    .mobile-menu--button {
        &:hover,
        &:focus {
            background-color: $color-white;
        }
        .icon {
            height: 8px;
            width: 8px;
            margin-left: 10px;
        }
    }

    .mobile-menu--back {
        @extend .link-animated-border;
        color: $color-copy;
        background-color: $color-white;
        font-size: 15px;
        margin-left: 40px;
        padding-left: 0px;
        &:before {
            transition: none !important;
            width: 100% !important;
        }

        .icon {
            fill: currentColor;
            height: 8px;
            width: 8px;
            margin-left: 10px;
        }
    }

    .mobile-submenu-child-item {
        @extend h5;
        padding: 20px 20px 0px 69px;

        > a {
            color: $color-copy-secondary;
        }
        > ul {
            padding-top: 20px;
        }
        .mega-menu-item-grandchild {
            padding: 0px 0px 15px 20px;
            font-size: 14px;
            font-weight: 400;
            > a {
                color: $color-copy;
            }
        }
    }
    .mobile-submenu-shop-all {
        margin-top: 20px;
        margin-bottom: 16px;
    }

    .mobile-submenu {
        display: none;
        overflow-y: scroll;
        display: none;
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 121px;
        z-index: 99;
        height: 100%;

        .mobile-menu--button {
            // border-bottom: 1px solid $color-light-gray;
        }
    }

    .mobile-submenu-level-3-list {
        // display: none;
        // padding: 15px;
        &.is-open {
            display: block;
        }
    }

    .is-open {
        .mobile-submenu {
            display: block;
        }
    }

    .mobile-submenu-level-3.is-open {
        .mobile-submenu-level-3-list {
            display: block;
        }
        .icon {
            transform: rotateX(180deg);
        }
    }
}
