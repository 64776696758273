/* ==========================================================================
EXTENSIONS / CONTACT / CONTACT
========================================================================== */

.x-contact-form .is-required span {
    color: $color-error;
}

.contact-infos {
   padding-right: 22px;
}

.verify-human .c-control-group__field:nth-child(2) {
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;
}

#contactMessage {
   resize: none;
}