/* ==========================================================================
EXTENSIONS / REVIEWS
========================================================================== */

.x-product-list__rating {
	@apply flex text-primary m-0;
	align-items: baseline;
}

.tgr_rating > input:checked ~ label,
.tgr_rating > input:not(:checked) ~ label:hover,
.tgr_rating > input:not(:checked) ~ label:hover ~ label {
	@apply text-primary #{!important};
}

.tgrating__input {
	@include visually-hidden();
}
.icon--star {
	fill: $color-primary;
}
.icon--half-star {
	fill: none
}

.icon--rating {
	// @apply fill-current stroke-primary mr-[3px];

	&.hollow {
		// @apply text-white;
	}
}

.no-reviews {
	.icon {
		@apply mb-2.5;
	}
}

.tgr_rating {
	@apply inline-flex;

	> label {
		@apply text-white float-right cursor-pointer;
	}
}

.tgreviews {
	@apply max-w-full;

	.icon--rating {
		@apply text-primary;
	}

	.filtered-reviews-message {
		@apply mb-5;
	}
}

.tgreviews-review__rating-info,
.tgreviews-average__star {
	.icon {
		@apply w-3.5 h-3.5;
	}
}

.tgreviews-review__title,
.tgreviews-average__text,
.tgreviews-average__star {
	@apply block mb-3;
}

.tgreviews-review__title {
	@apply font-menu mb-1.5;
}

.tgreviews-review__summary {
	@apply text-base mb-7;
}

.tgreviews-review__rating-info {
	@apply inline-block mr-1.5;
}

.tgreviews-rating-title {
	@apply font-bold;
}

.tgreviews-verified-buyer,
.tgreviews-review__user {
	@apply mb-1.5;
}

.tgreviews-verified-buyer {
	@apply font-body-tiny uppercase;
}

.tgreviews-review__user {
	@apply text-secondary-grey font-body-sm;

	span.tgreviews-rating-user-name {
		@apply text-copy;
	}
}

/* Breakdown */
.tgreviews-breakdown-container {
	@apply mt-5 md:mt-0;
}

.tgreviews-breakdown__rating {
	.tgreviews-breakdown__star {
		@apply ml-[5px];
	}
}

.tgreviews-breakdown__percent {
	@apply flex-1;
}

.tgreviews-breakdown__percentage {
	@apply h-[14px] bg-grayscale relative mt-0;
}

.tgreviews-rating-stars,
.tgreviews-average__star-overlay,
.tgreviews-review__rating-info--overlay,
.tgreviews-breakdown__star {
	@apply text-primary #{!important};
}

.tgreviews-breakdown__percentage-overlay {
	@apply bg-primary-yellow h-[14px] top-0 left-0;
}

.tgreviews-breakdown__rating,
.tgreviews-breakdown__percent,
.tgreviews-breakdown__count {
	@apply py-0.5 px-1 leading-[14px];
}

.tgreviews-breakdown,
.tgreviews-breakdown__rating {
	@apply flex items-center justify-end;
}

.review-count {
	@apply text-sm text-copy-secondary;
}

.tgreviews div {
	@apply box-border;
}

.tgreviews-average-overlay {
	@apply overflow-hidden absolute -top-1 left-0 whitespace-nowrap text-black;
}

.tgreviews-breakdown {
	@apply clear-left
}

.tgreviews-breakdown__rating,
.tgreviews-breakdown__count {
	@apply basis-auto flex-grow-0 flex-shrink-0;
}

.tgreviews-breakdown__rating {
	@apply text-right;
}

.tgreviews-breakdown__percent {
	@apply flex-auto;
}

.tgreviews-breakdown__star {
	@apply text-xs text-grayscale;
}

.tgreviews-half {
	@apply w-1/2 basis-1/2;
}

.tgreviews-breakdown,
.tgreviews-flex {
	@apply flex items-center;
}

.tgreviews-pagination {
	@apply flex items-center justify-center;

	span {
		@apply inline-block w-7 h-7 mx-[5px];

		a, strong {
			@apply block leading-7 rounded-full text-center;
		}

		strong {
			@apply text-white bg-copy;
		}
	}

	.tgreviews-pagination-prev,
	.tgreviews-pagination-next {
		.icon {
			@apply w-[17px] h-[17px];
		}
	}

	.tgreviews-pagination-prev {
		@apply mr-2.5;
	}

	.tgreviews-pagination-next {
		@apply ml-2.5;
	}
}


.tgreviews-label,
.tgreviews-pagination-label {
	@apply text-sm font-bold uppercase mr-4;
}

.tgreviews-label {
	@apply basis-auto flex-grow-0 flex-shrink-0;
}

.tgreviews-average {
	@apply text-center;
}

.tgreviews-average__star,
.tgreviews-average__star-overlay,
.tgreviews-review__rating-info,
.tgreviews-review__rating-info--overlay {
	@apply relative text-grayscale;
}

.tgreviews-average__star {
	@apply text-3xl leading-none inline-block relative mr-[5px] mb-2.5;

	.x-product-layout-purchase__reviews & {
		@apply font-body leading-none;
	}
}

.tgreviews-average__star-overlay,
.tgreviews-review__rating-info--overlay {
	@apply absolute top-0 left-0 bottom-0 overflow-hidden inline-block text-left whitespace-nowrap;
}

.tgreviews-average__text {
	@apply text-base text-copy-secondary font-bold normal-case;
}

.tgreviews-average__based_on {
	@apply text-base text-copy-secondary font-bold;
}

.tgreviews-write-review {
	@apply py-2 px-4 h-auto text-base leading-4 font-bold mb-8;
}

.tgreviews-sorting {
	.tgreviews-showing {
		@apply font-body-sm order-last lg:order-none;
	}

	.c-form-list {
		@apply flex items-center flex-wrap;
	}

	.c-form-list__item {
		@apply flex-shrink-0 flex-grow-0 basis-full lg:flex-1 lg:basis-1/2 lg:max-w-1/2 flex items-center mb-5;

		.c-form-label {
			@apply font-body-sm mb-0 mr-1.5;
		}

		.c-form-select {
			@apply w-[158px];

			.c-form-select__dropdown {
				@apply font-body-sm rounded-lg py-2 pl-2.5 pr-5 h-8;
				appearance: none;
				-webkit-appearance: none;
  				-moz-appearance: none;
				background: transparent;
				background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.435 0.5L0.56501 0.5C0.38177 0.5 0.205279 0.587273 0.100035 0.743117C-0.0387078 0.948831 -0.0309582 1.2187 0.109784 1.41403L5.05952 8.27117C5.16377 8.41506 5.32701 8.5 5.5 8.5C5.67299 8.5 5.83623 8.41506 5.94048 8.27117L10.8902 1.41403C11.031 1.2187 11.0387 0.948831 10.9 0.743117C10.7947 0.587273 10.6182 0.5 10.435 0.5Z' fill='%231EC0C9'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-size: 8px 5px;
				background-position: top 15px right 10px;
			}
		}
	}
}

.tgreviews-review {
	@apply leading-normal;
}

.tgreviews-rating-title {
	@apply mb-1 relative;
}

.tgreviews-verified-buyer {
	@apply font-body-tiny uppercase;
}

.tgreviews-rating-stars {
	@apply text-base;
	/* color: #FFA500; */
}

.tgreviews-review__rating {
	@apply py-0.5 px-[5px] text-[11px] border border-grayscale mr-2;
}

.tgreviews-review__additionalfields {
	@apply my-2 mx-0;
}

.tgreviews-review__addlfield-label {
	@apply font-bold;
}

// .tgreviews__form {}

.tgr_rating > input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.tgr_rating > label {
	font-size: 1.25rem;
	padding: 0 0.25rem;
	color: #DDDDDD;
	cursor: pointer;
	border-radius: 0.25rem;
}

.tgr_rating > label:first-child {
	margin-right: -0.25rem;
}

.tgr_rating:not(:hover) > input:checked ~ label,
.tgr_rating > label:hover,
.tgr_rating > label:hover ~ label,
.tgr_rating > input:checked + label:hover,
.tgr_rating > input:checked ~ label:hover,
.tgr_rating > label:hover ~ input:checked ~ label,
.tgr_rating > input:checked ~ label:hover ~ label {
	color: #FFA500;
}
