@counter-style dash {
    system: cyclic;
    symbols: '-';
    suffix: '  ';
}

img {
    max-width: 100%;
    height: auto;
}

.h1 {
    @apply font-semibold text-4xl md:text-6xl;
}
.h2 {
    @apply font-semibold text-[22px] leading-[32px] md:text-[28px] md:leading-[38px];
}
.h3 {
    @apply font-bold text-2xl md:text-3xl;
}
.h4 {
    @apply font-bold text-xl md:text-2xl
}
.h5 {
    @apply font-bold text-lg;
}
.h6 {
    @apply font-bold text-base;
}

h1, h2, h3, h4, h5, h6 {
    @apply mt-0 text-copy font-sans;

    a {
        @apply text-inherit;
    }
}

h1 {
    @apply h1;
}

h2 {
    @apply h2;
}

h3 {
    @apply h3;
}

h4 {
    @apply h4;
}

h5 {
    @apply h5;
}

h6 {
    @apply h6;
}

ol {
    @apply list-decimal;
}

ul {
    @apply list-disc;

    ul {
        @apply list-dash;
    }
}

.font-menu {
    @apply text-[15px] leading-snug font-bold;
}

.font-body {
    @apply text-base font-normal;
}

.font-body-sm {
    @apply text-sm font-semibold;
}

.font-body-tiny {
    @apply text-xs font-bold;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: $width-site + ($gutter * 2);
    padding: 0 $gutter;
    position: relative;
    width: 100%;
    &.ten-columns {
        max-width: ($column-width * 10) + ($grid-gutter * 9) + ($gutter * 2);
    }
    &.eight-columns {
        max-width: ($column-width * 8) + ($grid-gutter * 7) + ($gutter * 2);
    }
    &.six-columns {
        max-width: ($column-width * 6) + ($grid-gutter * 5) + ($gutter * 2);
    }
    &.fullwidth {
        max-width: 100%;
    }
}

#icons {
    @include visually-hidden();
}

.link-primary,
.link-secondary {
    transition: 0.3s ease;
    text-decoration: underline;
}

.link-primary {
    color: $link-color;
}

.link-animated-border {
    position: relative;

    &:before {
        content: "";
        width: 0;
        height: 5px;
        background: $color-primary;
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.3s ease-out;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.is-srOnly,
.visuallyhidden {
    @include visually-hidden();
}
