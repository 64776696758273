/* ==========================================================================
COMPONENTS / FORMS
========================================================================== */

/* Form list
=========================================== */

/**
* All form fields should be presented in a list so that
*
*   a) they are easier to navigate using a screen reader;
*   b) if CSS fails to load for any reason, the user is still presented with a
*      well-formatted list of fields.
*/
.c-form-list:not(.grid) {
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-form-list__item,
.c-form__item {
    @apply block w-full mb-3;
}

// .c-form-list .c-form-list__item:last-child,
// .c-form-list .c-form__item:last-child {
// 	margin-bottom: 0;
// }

@media (min-width: 48em) {
    // .c-form-list__item,
    // .c-form__item {
    // 	max-width: 20em;
    // }

    /* For any inputs that need to be displayed full-width */
    .c-form-list__item--full,
    .c-form__item--full {
        max-width: 100%;

        .c-form-input {
            @apply block;
        }
    }
}

.form_row {
    margin-bottom: 1rem;
}

/* Form labels
=========================================== */

/**
* All LABEL elements must also carry a class of `.c-form-label`. By applying
* these styles to a class and not to the LABEL element directly, we are free
* to reuse the same look-and-feel on spoofed LABEL elements. E.g. when we have
* a ‘meta label’ covering a number of sub labels:
*
*   Gender
*   • Male  • Female  • Rather not say
*
*/
.c-form-label {
    @apply font-menu inline-block mb-2;
}

/* Form text inputs
=========================================== */

/**
* All text-like form inputs require a class of `.c-form-input`: we do not use
* selectors like `input[type="text"] {}`.
*
* 1. Fix for IE 10/11 removing vertical padding from inputs which was being
*	  ignored.
* 2. Padding added via line-height/height to re-center text for all browsers
* 	  (38px to account for border).
*/
.c-form-input {
    @apply inline-block mb-[5px] px-4 py-2.5 w-full h-[42px] leading-none bg-white text-copy-secondary border border-copy-secondary rounded-full appearance-none font-body font-bold focus:border focus:border-solid focus:border-transparent focus:z-10;

}

select.c-form-input {
    @apply pr-6;
}

/**
* For use on large text inputs such as textareas
*/
.c-form-input--long {
    @apply h-24 rounded-[21px];
}

.c-form-input--file {
    padding: 0;
    line-height: calc(2em - 2px);
}

/* Form select inputs
=========================================== */
.c-form-select {
    @apply inline-block relative w-full text-base leading-normal;

    select {
        @apply inline-block px-4 py-2.5 w-full h-[42px] leading-none bg-white text-copy-secondary border rounded-full appearance-none font-body font-bold cursor-pointer;
    }
}

/**
* Gradient to hide the overflow of long labels
*
* 1. Stretch to full height on the right (taking into account border width)
*/
// .c-form-select::before {
// 	position: absolute;
// 	top: $global-border-radius; /* [1] */
// 	right: $global-border-radius; /* [1] */
// 	bottom: $global-border-radius; /* [1] */
// 	width: 2em;
// 	background: $color-white;
// 	background: -ms-linear-gradient(right, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0));
// 	background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0));
// 	border-radius: 0 $global-border-radius $global-border-radius 0;
// 	content: "";
// 	pointer-events: none;
// }

// /**
// * Custom drop-down indicator icon
// */
// .c-form-select::after {
// 	position: absolute;
// 	top: 50%;
// 	right: 0.5em;
// 	margin-bottom: 0.25em;
// 	width: 0.5em;
// 	height: 0.5em;
// 	background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.58908 0H0.410917C0.277651 0 0.149294 0.0545454 0.0727526 0.151948C-0.0281511 0.280519 -0.0225151 0.449188 0.0798432 0.571266L3.67965 4.85698C3.75547 4.94692 3.87419 5 4 5C4.12581 5 4.24453 4.94692 4.32035 4.85698L7.92016 0.571266C8.02252 0.449188 8.02815 0.280519 7.92725 0.151948C7.85071 0.0545454 7.72235 0 7.58908 0Z' fill='%231EC0C9'/%3E%3C/svg%3E%0A") no-repeat center center;
// 	background-size: contain;
// 	content: "";
// 	pointer-events: none;
// 	transform: translateY(-50%);
// }

.c-form-select__dropdown:not(.selectize-control,.selectize-dropdown) {
    @apply inline-block px-4 py-2.5 w-full h-[42px] leading-none bg-white text-copy-secondary border border-copy-secondary rounded-full appearance-none font-body font-bold cursor-pointer;
}

.c-form-select__dropdown.#{$selectize}-control {
    @apply p-0 border-none shadow-none;

    .#{$selectize}-input {
        @apply inline-block px-4 py-2.5 w-full h-[42px] bg-white text-copy-secondary border border-copy-secondary rounded-full appearance-none font-body font-bold cursor-pointer shadow-none before:hidden after:hidden before:content-none after:content-none;

        input:focus {
            @apply outline-none border-none;
        }

        &.focus,
        &:focus {
            @apply ring-1 border-blue-600;
        }
    }

    .#{$selectize}-dropdown {
        @apply m-0 mt-1.5 rounded-[20px] overflow-hidden shadow-none drop-shadow-none border border-solid border-copy-secondary;

        .optgroup-header {
            @apply h5 m-0;
        }

        &-content {
            @apply py-1.5;
        }

        [data-selectable] .highlight {
            @apply bg-transparent rounded-none underline;
        }
    }
}

// .c-form-select__dropdown::-ms-expand {
// 	display: none;
// }


/* Form checkbox inputs
=========================================== */
.c-form-checkbox {
    @apply inline-block w-auto cursor-pointer text-base -ml-1.5;
}

/**
* For cases where checkboxes or radio buttons need to display inline.
*/
.c-form-checkbox--inline {
    width: auto;
    margin-right: 2em;
}

/**
* Hide the default input visually to utilise keyboard functionality and allow
* for custom input styles.
*/
.c-form-checkbox__input {
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    position: absolute;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    white-space: nowrap;
}

.c-form-checkbox__caption {
    @apply flex items-center relative p-1.5 select-none h-full text-base font-bold
            before:content-[''] before:inline-block before:relative before:w-5 before:h-5 before:transition-colors before:bg-white before:rounded-md before:mr-1.5 before:border-2 before:border-copy before:flex-shrink-0
            after:content-[''] after:block after:absolute after:w-3 after:h-3 after:transition-colors after:bg-primary after:rounded-[3px] after:top-1/2 after:left-2.5 after:-translate-y-1/2 after:scale-0 after:opacity-0;

    // &--image {

    // }

    .c-form-checkbox--radio & {
        @apply before:rounded-full after:rounded-full;
    }

    .c-form-checkbox__input:focus + & {
        @apply ring-1 border-blue-600;
    }

    .c-form-checkbox__input:checked + & {
        @apply before:transition-colors after:block after:scale-100 after:opacity-100;

        .c-form-checkbox--radio & {
            @apply after:rounded-full after:bg-contain after:bg-center;
        }
    }

    .c-form-checkbox__input:disabled + & {
        @apply opacity-50 cursor-not-allowed before:bg-grayscale-almostWhite before:border-grayscale-almostWhite;
    }
}

/* Disabled Form Elements
=========================================== */
.c-form-input[disabled],
.c-form-select__dropdown[disabled],
.c-form-checkbox__input[disabled] {
    background-color: $color-lightest-gray;
}

/* Form errors
=========================================== */
/**
* Errors are handled by adding the .has-error class to the field's parent –
* usually the .c-form-list__item.
*
* The `invalid` class is included here to work with code output by Miva Merchant on some pages.
*/

.has-error,
.invalid {
    color: $color-error;
}

/**
* Change form field styles
*/
.has-error .c-form-input,
.invalid .c-form-input,
.c-form-input.has-error,
.has-error .c-form-select__dropdown,
.invalid .c-form-select__dropdown,
.c-form-select__dropdown.has-error {
    border-color: $color-error;
}

.has-error .c-form-input:focus,
.invalid .c-form-input:focus,
.c-form-input.has-error:focus,
.has-error .c-form-select__dropdown:focus,
.invalid .c-form-select__dropdown:focus,
.c-form-select__dropdown.has-error:focus {
    border-color: $color-white;
    box-shadow: inset 0 0 0.5em 0 rgba($color-error, 0.75);
}

/**
* Change checkbox/radio indicator border color
*/
.has-error .c-form-checkbox__caption::before,
.invalid .c-form-checkbox__caption::before,
.c-form-checkbox__caption.has-error::before,
.c-form-checkbox__caption:invalid::before {
    border-color: $color-error;
}

/* Input sizing modifiers
=========================================== */
.c-form-input--large {
    height: calc(3.875em - (#{$global-border-width} * 2)); /* [2] */
    line-height: calc(3.875em - (#{$global-border-width} * 2)); /* [2] */
}

.c-form-input--huge {
    height: calc(4.5em - (#{$global-border-width} * 2)); /* [2] */
    line-height: calc(4.5em - (#{$global-border-width} * 2)); /* [2] */
}

.c-form-input--large.c-form-select__dropdown,
.c-form-input--huge.c-form-select__dropdown {
    line-height: inherit;
}

.c-form-group {
    @apply flex flex-nowrap -ml-0.5;
}

.c-form-list__item {
    .c-form-group & {
        @apply pl-0.5;

        .c-form-input,
        .c-form-select,
        .button {
            @apply rounded-none;
        }

        &:first-child {
            .c-form-input,
            .c-form-select,
            .button {
                @apply rounded-l-full;
            }
        }

        &:last-child {
            .c-form-input,
            .c-form-select,
            .button {
                @apply last:rounded-r-full;
            }
        }
    }
}

.c-form-colorpicker {
    &__input {
        @apply p-0 w-[42px];

        &::-webkit-color-swatch-wrapper {
            @apply rounded-full overflow-hidden p-1;
        }

        &::-webkit-color-swatch {
            @apply rounded-full;
        }
    }
}

/* ==========================================================================
ELEMENTS / FORMS
========================================================================== */

/**
* Semantically, we'd like all forms to be built with FIELDSETs and LEGENDs, but
* we currently don't want to visually see them.
*
* 1. Visually hide content, leaving it accessible to screen readers and ATs.
*    http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
fieldset {
    margin-bottom: 0;
    border: none;
}

legend { /* [1] */
    width: 1px;
    height: auto;
    margin: 0;
    padding: 0;
    position: absolute;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    white-space: nowrap;
}

/**
* Make sure our form elements don't use any UA-specific font styles: we want
* them to use ours. This may need reverting as more design information becomes
* available, and we start putting together more complete forms.
*/
input {
    font: inherit;
}

button,
input,
html,
select,
textarea {
    letter-spacing: normal;
}

/**
    * Ensure that, when focused, the iOS Input Zoom doesn't happen.
    */
@media screen and (-webkit-min-device-pixel-ratio: 2) {
    input[type="color"]:focus,
    input[type="date"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="email"]:focus,
    input[type="month"]:focus,
    input[type="number"]:focus,
    input[type="password"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="text"]:focus,
    input[type="time"]:focus,
    input[type="url"]:focus,
    input[type="week"]:focus,
    select:focus,
    textarea:focus {
        font-size: 16px;
    }
}
