/* Site Header
=========================================== */

.header {
    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 101;
        left: 0;
    }
    &.sticky-header {
        position: sticky;
        top: 0;
        z-index: 102;
    }
    .header-main {
        z-index: 102;
        position: relative;
        background-color: #ffffff;
        padding: 20px 0;
        @include media-query($small-down) {
            padding: 10px 0;
        }
        .wrapper {
            position: static;
        }
    }
    .button--mobile-menu {
        .icon {
            width: 25px;
            height: 17px;
        }
    }
}

.t-site-header__top-navigation {
    font-size: 13px;
    background-color: $color-quinary;
    color: $color-copy-secondary;
}

.t-site-header__message {
    display: inline-block;
    padding: 6px 0;
}

.t-site-header__links [class^="u-icon-"] {
    margin-right: 0.25em;
    font-size: 1.25rem;
    vertical-align: text-bottom;
}

.t-site-header__links a {
    color: $color-white;
}

.t-site-header__links a:hover {
    text-decoration: none;
    opacity: 0.75;
    transition: 0.3s;
}

.t-site-header__links .o-layout__item {
    padding: 1em 1rem;
}

@media (min-width: 48em) {
    .t-site-header__links .o-layout__item:not(:first-of-type) {
        border-left: $global-border-width solid $color-light-gray;
    }
}

.t-site-header__links .x-transfigure-navigation__footer {
    display: flex;
    justify-content: flex-end;
}

.t-site-header__links .x-transfigure-navigation__footer-link {
    margin: 0 1rem;
    padding: 0;
    font-size: inherit;
    font-weight: 400;
    letter-spacing: inherit;
}

.t-site-header__basket-link {
    position: relative;
}

.t-site-header__basket-link--mobile {
    display: flex;
    font-size: 2rem;
}

.t-site-header__basket-link-quantity--mobile {
    display: inline-block;
    min-width: 2em;
    height: 2em;
    font-size: 0.75rem;
    line-height: 2;
    text-align: center;
    border-radius: 50%;
    touch-action: none;
    transform: translate(-0.75em, -1em);
    z-index: -1;
}

.t-global-account {
    width: 100vw;
    max-width: 22.5rem;
    padding: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $color-lightest-gray--tinted;
    border: $global-border-width solid $color-lightest-gray;
    box-shadow: 0 2px 1px -2px $color-light-gray;
}

.t-global-account__form {
    margin-bottom: 0;
}

.t-global-account__form .c-form-list__item:last-of-type {
    margin-bottom: 0;
    padding-top: 0.5em;
}

.t-site-header__masthead {
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
    font-size: 0.875rem;
}

.t-site-header__logo {
    margin-bottom: 0;

    img {
        max-width: 200px;
        @include media-query($small-down) {
            max-width: 126px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.t-site-header__search-form {
    height: 42px;
    margin: 0 auto;
    max-width: 650px;
    position: relative;
    border: $global-border-width solid $color-copy-secondary;
    border-radius: 20px;

    .button {
        padding: 0 20px;
        line-height: 35px;
        height: 40px;
        &:hover {
            opacity: 0.7;
        }
        .icon--search {
            width: 18px !important;
            height: 18px !important;
        }
    }

    .c-form-list__item {
        margin-bottom: 0;
        margin-left: auto;
    }

    .c-form-input {
        border-radius: 20px 0 0 20px;
        padding: 0 20px;
        height: 40px;
    }
}

.t-site-header__masthead-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .navUser-item {
        background-color: transparent;
        border: 0 none;
        padding: 0 10px;
        text-align: center;
        @include media-query($large-up) {
            padding: 0 15px;
        }
        .navUser-item--text {
            color: $color-copy-secondary;
            display: block;
            font-size: 13px;
            font-weight: 600;
        }
        &:hover {
            // opacity: 0.6;
            // transition: 0.3s;
            cursor: pointer;

            .navUser-item--text {
                color: $color-copy;
                text-decoration: underline;
                font-size: 13px;
            }
        }
    }
    .icon {
        margin-right: 0.25em;
        margin-bottom: 4px;
        font-size: 13px;
        vertical-align: text-bottom;

        @include media-query($large-up) {
            width: 26px;
            height: 26px;
        }
    }
    .icon--cart {
        height: 25px;
        width: 32px;
    }
}

.t-site-header__basket-link-quantity {
    border-radius: 50%;
    color: $color-white;
    font-size: 12px;
    font-weight: 700;
    height: 18px; // 1
    line-height: 18px; // 1
    margin-left: 2px;
    text-align: center;
    width: 18px; // 1
    position: absolute;
    top: -1px;
    right: 17px;
    @include media-query($large-up) {
        right: 21px;
    }
}

.site-navigation {
    background-color: $color-white;
}
